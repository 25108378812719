import { reactive, InjectionKey } from '@vue/composition-api'

export type NotificationType = {
  state: {
    message: string
    type: string
    position: boolean
    visible: boolean
  }
  show: (message: string, type?: string, position?: boolean) => void
  hide: () => void
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotification = () => {
  const state = reactive({
    message: '',
    type: 'default',
    position: false,
    visible: false,
  })

  const show = (message: string, type = 'default', position = false) => {
    state.message = message
    state.type = type
    state.position = position
    state.visible = true
  }

  const hide = () => {
    state.visible = false
  }

  return {
    state,
    show,
    hide,
  }
}

export const NotificationInjectKey: InjectionKey<NotificationType> = Symbol('notification')
