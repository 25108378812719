import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/plugins/veeValidate'
import AppConfig from '@/config'
import authenticate from '@/plugins/authenticate'
import '@/assets/scss/app.scss'
import VueCompositionAPI from '@vue/composition-api'

import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

Vue.use(VueCompositionAPI)
Vue.use(authenticate, { store, router })
Vue.config.productionTip = false

Vue.prototype.$appConfig = AppConfig

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
