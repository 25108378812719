























































import { defineComponent, PropType } from '@vue/composition-api'
import { ProfileProps } from '@/const/userData'

type Props = Pick<ProfileProps, 'life' | 'rank' | 'investment'>

export default defineComponent({
  props: {
    life: {
      type: Number as PropType<Props['life']>,
      required: false,
      default: 0,
    },
    rank: {
      type: Number as PropType<Props['rank']>,
      required: false,
      default: 0,
    },
    investment: {
      type: Number as PropType<Props['investment']>,
      required: false,
      default: 0,
    },
  },
  setup() {
    return {}
  },
})
