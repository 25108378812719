


















import { defineComponent, PropType, onBeforeUpdate, ref, SetupContext, inject, onMounted } from '@vue/composition-api'
import { AuthState } from '../types'
import { imageUrlReplace } from '../libs'
import { NavigationInjectKey } from '../hooks/useNavigation'
export type Props = {
  show: boolean | null
}
export default defineComponent({
  props: {
    value: {
      type: Boolean as PropType<Props['show']>,
      required: false,
      default: null,
    },
  },
  setup(_, ctx: SetupContext) {
    const logined = ref()
    const NavigationService = inject(NavigationInjectKey)
    const user = ref<AuthState | null | undefined>(null)
    const navigation = ref<{ menus: Record<'label' | 'link', string>[] }>({ menus: [] })
    onMounted(async () => {
      navigation.value = NavigationService?.navigation as { menus: Record<'label' | 'link', string>[] }
    })
    onBeforeUpdate(() => {
      const currentUser = ctx.root.$auth.user()
      logined.value = currentUser ? true : false
      if (logined.value) {
        user.value = currentUser
      }
    })
    return { user, logined, imageUrlReplace, navigation }
  },
})
