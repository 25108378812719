







































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { ProfileProps } from '@/types'

type Props = Pick<ProfileProps, 'username' | 'email'>

export default defineComponent({
  props: {
    username: {
      type: String as PropType<Props['username']>,
      required: true,
      default: '',
    },
    email: {
      type: String as PropType<Props['email']>,
      required: true,
      default: '',
    },
  },
  setup(props, context) {
    const valid = ref(true)
    const rules = ref([
      (v: string) => !!v || 'ユーザー名は必須項目です',
      (v: string) => (v && v.length <= 16) || 'ユーザー名は16文字以内で入力してください',
    ])
    const proxyuser_name = computed({
      get: () => props.username,
      set: (val) => context.emit('update:username', val),
    })
    const content = {
      username: {
        key: 'username',
        name: 'ユーザー名',
        rule: 'required|max:16',
        type: 'input',
        description:
          '※ユーザー名は全ての会員に表示されるので、ニックネーム等、本名以外でご利用になることをお勧めいたします。<br>16文字以内の全角、半角でお決めになってください。また、他の方と重複した場合はご利用になれません。<br>Discordのサロンメンバーになっている方は、そちらと同一のユーザー名をご使用下さい。',
      },
      email: {
        key: 'email',
        name: 'メールアドレス',
        type: 'text',
      },
      password: {
        key: 'password',
        name: 'パスワード',
        rule: 'required|min:8|max:256|password',
        type: 'input',
        description: '※パスワードは、半角英数8文字以上256文字以内',
      },
      passwordConfirm: {
        key: 'passwordConfirm',
        name: 'パスワード(確認)',
        rule: 'required|confirmed:パスワード',
        type: 'input',
      },
    }

    return { proxyuser_name, content, valid, rules }
  },
})
