























import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import InputComponent from '@/components/Investment/Subscription/Input.vue'
import ConfirmComponent from '@/components/Investment/Subscription/Confirm.vue'
import CompleteComponent from '@/components/Investment/Subscription/Complete.vue'

export type Props = {
  input: string
  email: string
  confirm: boolean
  complete: boolean
}

export default defineComponent({
  components: { InputComponent, ConfirmComponent, CompleteComponent },
  props: {
    input: {
      type: String as PropType<Props['input']>,
      required: false,
      default: '',
    },
    email: {
      type: String as PropType<Props['email']>,
      required: true,
    },
    confirm: {
      type: Boolean as PropType<Props['confirm']>,
      required: true,
    },
    complete: {
      type: Boolean as PropType<Props['complete']>,
      required: true,
    },
  },
  setup(props, context) {
    const stepState = ref(1)
    const proxyInput = computed({
      get: () => props.input,
      set: (val) => context.emit('update:input', val),
    })
    const proxyConfirm = computed({
      get: () => props.confirm,
      set: (val) => context.emit('update:confirm', val),
    })
    const proxyComplete = computed({
      get: () => props.complete,
      set: (val) => context.emit('update:complete', val),
    })

    const handleConfirm = () => {
      stepState.value = 2
      proxyConfirm.value = true
    }
    const handleApply = () => {
      stepState.value = 3
      context.emit('handle-apply')
    }
    const handleReturn = () => {
      stepState.value = 1
      proxyConfirm.value = false
    }

    return { proxyInput, proxyConfirm, proxyComplete, handleConfirm, handleApply, handleReturn, stepState }
  },
})
