















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppFooter',
  setup() {
    const linkList = {
      top: {
        label: 'TOP',
        link: 'https://yuzenfamily.com',
        target: '_self',
      },
      sitepolicy: {
        label: 'サイトポリシー',
        link: 'https://yuzenfamily.com/sitepolicy.php',
        target: '_blank',
      },
      privacy: {
        label: 'プライバシーポリシー',
        link: 'https://yuzenfamily.com/privacy.php',
        target: '_blank',
      },
    }
    return { linkList }
  },
})
