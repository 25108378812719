





import { defineComponent, onMounted, PropType, watch } from '@vue/composition-api'
import { ChartOptions, ChartType } from 'chart.js'
import { DatasetType } from '@/types'
import { useChart } from '@/hooks/useChart'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<ChartType>,
      default: 'line',
    },
    dataset: {
      type: Object as PropType<DatasetType | undefined>,
      required: true,
    },
    options: {
      type: Object as PropType<ChartOptions>,
      required: false,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
        }
      },
    },
  },
  setup(props) {
    const { canvasRef, render, refresh } = useChart(props.type, props.options)

    watch(
      () => props.dataset?.datasets,
      () => {
        refresh(props.dataset)
      },
      {
        deep: true,
      }
    )
    onMounted(() => {
      render(props.dataset)
    })
    return {
      canvasRef,
    }
  },
})
