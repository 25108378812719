

























import { defineComponent, reactive, ref, onMounted, SetupContext, computed, inject } from '@vue/composition-api'
import SignupInput from '@/components/Signup/Input.vue'
import SignupConfirm from '@/components/Signup/Confirm.vue'
import { RegisterValidateResponseType } from '../types'
import axios, { AxiosResponse } from 'axios'
import { useRequest } from '@/hooks/useRequest'
import { CreateUserRequestType } from '../types'
import { NavigationInjectKey } from '@/hooks/useNavigation'
export default defineComponent({
  components: {
    SignupInput,
    SignupConfirm,
  },
  setup(_, ctx: SetupContext) {
    const { request } = useRequest()
    const navi = inject(NavigationInjectKey)
    const confirm = ref<boolean>(false)
    const errors = ref(false)
    const processing = ref(false)
    const complited = ref(false)
    const renderState = computed((): string => {
      if (errors.value) {
        return 'impossible'
      }
      if (errors.value === false && complited.value === true) {
        return 'complete'
      }
      return 'idle'
    })
    const inputs = reactive<CreateUserRequestType>({
      role_id: 3,
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      name: '',
      name_kana: '',
      date_of_birth: '1990-01-01',
      agree: false,
    })

    const submit = () => {
      confirm.value = true
      window.scroll({ top: 0 })
    }

    const modify = () => {
      confirm.value = false
    }

    const loginEventHandler = async () => {
      const { method, uri } = ctx.root.$appConfig.endpoints.login
      const result = await request(uri, method, {
        email: inputs.email,
        password: inputs.password,
        type: 'authorization',
      })
      if (result === false) {
        return false
      }
      ctx.root.$auth.login(result)
      navi?.setNavigation(result.user)
      return true
    }

    const handleComplete = async () => {
      processing.value = true
      const { uri } = ctx.root.$appConfig.endpoints.createUser
      const result = await axios
        .post(uri, inputs)
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
        .finally(() => {
          processing.value = false
        })
      if (result) {
        await loginEventHandler()
        complited.value = result
      }
    }

    const registerValidate = async (key: string): Promise<boolean> => {
      const endpoint = ctx.root.$appConfig.endpoints.registerValidate
      const url = endpoint.uri.replace(':uuid', key)
      const response = await axios
        .get(url)
        .then((res: AxiosResponse<RegisterValidateResponseType>) => {
          inputs.email = res.data.email
          return true
        })
        .catch(() => {
          return false
        })
      return !response
    }

    onMounted(async () => {
      const key = ctx.root.$route.query?.key
      if (!key) {
        errors.value = true
      }
      errors.value = await registerValidate(key as string)
    })
    return { inputs, submit, confirm, modify, handleComplete, processing, renderState }
  },
})
