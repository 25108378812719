import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Register from '../views/Register.vue'
import Signup from '../views/Signup.vue'
import Faqs from '../views/Faqs.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Dashboard from '../views/Dashboard.vue'
import Profile from '../views/Profile.vue'
import Investment from '../views/Investment.vue'
import InvestmentAdd from '../views/Investment/Add.vue'
import InvestmentThread from '../views/Investment/Thread.vue'
import Posts from '../views/Posts/index.vue'
import DataTableList from '../views/DataTableList.vue'
import Detail from '../views/Detail.vue'
import Consultation from '../views/Consulation.vue'
import InvestmentRecords from '../views/Investment/Records.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: Faqs,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/nickname-login',
    name: 'NicknameLogin',
    component: () => import('../views/NicknameLogin.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment',
    name: 'Investment',
    component: Investment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment/add',
    name: 'InvenstmentAdd',
    component: InvestmentAdd,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment/threads',
    name: 'InvestmentThread',
    component: InvestmentThread,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment/threads/user/:id',
    name: 'InvestmentThreadUser',
    component: () => import('../views/Investment/UserThread.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment/:id/edit',
    name: 'InvenstmentEdit',
    component: () => import('@/views/Investment/Edit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment/records',
    name: 'InvestmentRecords',
    component: InvestmentRecords,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/posts/add',
    name: 'PostsAdd',
    component: Posts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/posts/:id/edit',
    name: 'PostsEdit',
    component: Posts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/information',
    name: 'Information',
    component: DataTableList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/workshop',
    name: 'Workshop',
    component: DataTableList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: DataTableList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/information/:id',
    name: 'InformationView',
    component: Detail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/workshop/:id',
    name: 'WorkshopId',
    component: Detail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/:id',
    name: 'EventsId',
    component: Detail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investment-information/:id',
    name: 'InvestmentId',
    component: Detail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/consultation',
    name: 'Consultation',
    component: Consultation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin/Index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'DiscordDashboard',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/procedure',
    name: 'Procedure',
    component: () => import('../views/Procedure.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/password/forgot',
    name: 'PasswordForgot',
    component: () => import('../views/PasswordForgot.vue'),
  },
  {
    path: '/password/reset',
    name: 'PasswordReset',
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/change-email',
    name: 'ChangeEmail',
    component: () => import('../views/ChangeEmail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/errors/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
