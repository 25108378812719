





























































import { defineComponent, onBeforeMount, inject, reactive, ref, computed } from '@vue/composition-api'
import { useRequest } from '@/hooks/useRequest'
import StatusComponent from '@/components/Profile/Status.vue'
import InfoComponent from '@/components/Profile/Info.vue'
import ProfileComponent from '@/components/Profile/Profile.vue'
import ErrorComponent from '@/components/Error.vue'
import ChangeEmailModal from '@/components/Profile/Modal/ChangeEmail.vue'
import ChangePasswordModal from '@/components/Profile/Modal/ChangePassword.vue'
import LinkComponent from '@/components/Parts/Link.vue'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { ProfileProps } from '@/types'
import { ValidationObserver } from 'vee-validate'
import Discord from '@/components/Profile/Discord.vue'
export default defineComponent({
  components: {
    StatusComponent,
    InfoComponent,
    ProfileComponent,
    ErrorComponent,
    ChangeEmailModal,
    ChangePasswordModal,
    LinkComponent,
    Discord,
  },
  setup(_, context) {
    const tabs = ref(0)
    const { request, requestFormData } = useRequest()
    const notificationService = inject(NotificationInjectKey)
    const inputs = reactive<Partial<ProfileProps>>({})
    const openSnackbar = ref<boolean>(false)
    const snackbarTimeout = 2000
    const error = ref<boolean>(false)
    const message = ref<string>()
    const chEmailFlg = ref<boolean>(false)
    const chPasswordFlg = ref<boolean>(false)
    const user = computed(() => context.root.$auth.user())

    onBeforeMount(() => {
      inputs.name = user.value?.name
      inputs.username = user.value?.username
      inputs.email = user.value?.email
      inputs.email_confirmation = ''
      inputs.name_kana = user.value?.name_kana || ''
      inputs.date_of_birth = user.value?.date_of_birth || ''
      inputs.image_url = user.value?.image_url
      inputs.time_of_birth = user.value?.time_of_birth
      inputs.sex = user.value?.sex || ''
      inputs.brithpalce = user.value?.brithpalce
      inputs.address = user.value?.address
      inputs.introducer = user.value?.introducer
      inputs.relationship_introducer = user.value?.relationship_introducer
      inputs.pr = user.value?.pr
      inputs.current_password = ''
      inputs.password = ''
      inputs.password_confirmation = ''
      inputs.password = ''
    })

    const handleFileUpload = async (file: File) => {
      const { uri } = context.root.$appConfig.endpoints.fileAvatarUpload
      const result = await requestFormData(uri, 'file', file)
      if (result === false) {
        notificationService?.show('ファイルアップロードに失敗しました。', 'error')
        return false
      } else {
        inputs.image_url = result.url
      }
    }

    const usernameLengthValidate = () => {
      return !((inputs.username as string).length > 16)
    }

    const handleUpdate = async () => {
      const observer = context.refs.observer as InstanceType<typeof ValidationObserver>
      if (!(await observer.validate()) || !usernameLengthValidate()) {
        error.value = true
        window.scrollTo({ top: 0, behavior: 'smooth' })
        notificationService?.show('更新できません。エラー内容を確認ください。', 'error')
      } else {
        const { uri, method } = context.root.$appConfig.endpoints.putUser
        const userid = user.value?.id
        const requestUri = uri.replace(/:id/, String(userid || ''))
        const param = {
          status_id: inputs.status_id,
          username: inputs.username,
          name: inputs.name,
          name_kana: inputs.name_kana,
          date_of_birth: inputs.date_of_birth,
          sex: inputs.sex,
          image_url: inputs.image_url,
          time_of_birth: inputs.time_of_birth,
          brithpalce: inputs.brithpalce,
          address: inputs.address,
          introducer: inputs.introducer,
          relationship_introducer: inputs.relationship_introducer,
          pr: inputs.pr,
        }
        const result = await request(requestUri, method, param)
        if (result === false) {
          notificationService?.show('通信に失敗しました。', 'error')
          return false
        }
        notificationService?.show('更新を完了しました。', 'success')
      }
    }

    const handleChangeCompleteEmail = async (newEmail: string, newEmailConf: string) => {
      const { uri, method } = context.root.$appConfig.endpoints.changeEmailRequest
      const userid = user.value?.id
      const requestUri = uri.replace(/:id/, String(userid || ''))
      const result = await request(requestUri, method, { email: newEmail, email_confirmation: newEmailConf })
      if (result === false) {
        notificationService?.show('メールアドレスの変更処理に失敗しました管理者に連絡してください。', 'error')
        return false
      } else {
        notificationService?.show('メールアドレス変更のメールを送信しました。', 'success')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const refs = context.refs as any
      refs['changeEmailModal']?.resetEmailState()
    }

    const handleChangeCompletePassword = async (nowPass: string, newPass: string, newPassConf: string) => {
      const { uri, method } = context.root.$appConfig.endpoints.patchPassword
      const userid = user.value?.id
      const requestUri = uri.replace(/:id/, String(userid || ''))
      const result = await request(requestUri, method, {
        current_password: nowPass,
        password: newPass,
        password_confirmation: newPassConf,
      })
      if (result === false) {
        notificationService?.show('通信に失敗しました。', 'error')
        return false
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const refs = context.refs as any
        refs['changePasswordModal']?.resetPasswordState()
        notificationService?.show('パスワードを更新しました。', 'success')
      }
    }

    const handleOpenChangeEmail = () => {
      chEmailFlg.value = true
    }
    const handleChangePassword = () => (chPasswordFlg.value = true)

    return {
      user,
      inputs,
      openSnackbar,
      snackbarTimeout,
      error,
      message,
      chEmailFlg,
      chPasswordFlg,
      handleUpdate,
      handleOpenChangeEmail,
      handleChangePassword,
      handleFileUpload,
      handleChangeCompleteEmail,
      handleChangeCompletePassword,
      tabs,
    }
  },
})
