import { onMounted, reactive, toRefs } from '@vue/composition-api'
import AppConfig from '@/config'
import { apiClient } from '@/libs'
import { PaginationResponseType, ThreadItemType } from '@/types'
import { AxiosResponse } from 'axios'
import { headerWithAccessToken, refreshAccessToken } from '@/libs'

type ThreadState = {
  url: string
  loading: boolean
  data: PaginationResponseType<ThreadItemType[]>
  errors: boolean
  immediately: boolean
}
export const useThread = (immediately = false) => {
  const state = reactive<ThreadState>({
    url: AppConfig.endpoints.threads.uri,
    loading: false,
    data: {
      current_page: 1,
      data: [],
      first_page_url: '',
      from: 1,
      last_page: 1,
      last_page_url: '',
      next_page_url: null,
      path: '',
      per_page: 15,
      prev_page_url: null,
      to: 1,
      total: 0,
      links: [],
    },
    errors: false,
    immediately: immediately,
  })

  const remove = async (id: number) => {
    const { uri } = AppConfig.endpoints.threadDelete
    const url = uri.replace(':id', id.toString())
    return await apiClient
      .delete(url, {
        headers: headerWithAccessToken(url),
      })
      .then((res) => {
        refreshAccessToken(res.headers)
        return true
      })
      .catch((err) => {
        refreshAccessToken(err.response.headers)
        return false
      })
  }
  const fetch = async (params = {}) => {
    state.loading = true
    const headers = headerWithAccessToken(state.url)
    const options = {
      params,
      headers,
    }
    await apiClient
      .get(state.url, options)
      .then((res: AxiosResponse<PaginationResponseType<ThreadItemType[]>>) => {
        refreshAccessToken(res.headers)
        state.data = res.data
      })
      .catch(() => {
        state.errors = true
      })
      .finally(() => {
        state.loading = false
      })
  }

  const paginate = async (page: number) => {
    const params = {
      params: {
        page: page,
      },
    }
    await fetch(params)
  }

  const setUrl = (url: string) => {
    state.url = url
  }

  onMounted(async () => {
    if (state.immediately) {
      await fetch()
    }
  })
  return {
    ...toRefs(state),
    fetch,
    remove,
    paginate,
    setUrl,
  }
}
