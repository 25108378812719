





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    input: {
      type: String,
      required: true,
    },
  },
  setup(_, context) {
    const handleApply = () => {
      context.emit('handle-apply')
    }
    const returnInput = () => {
      context.emit('return-input')
    }
    return { handleApply, returnInput }
  },
})
