



































































































import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import LinkComponent from '@/components/Parts/Link.vue'
import DialogComponent from '@/components/Parts/Dialog.vue'
import ReplyModal from '@/components/Investment/Threads/ReplyModal.vue'
import Replies from '@/components/Investment/Threads/Replies.vue'
import { Pair, EntryDirection, ThreadProps } from '@/const/investment'

export default defineComponent({
  components: { LinkComponent, DialogComponent, ReplyModal, Replies },
  props: {
    thread: {
      type: Object as PropType<ThreadProps>,
      required: true,
    },
  },
  setup(props, context) {
    const pair = Pair.filter((v) => v.value === props.thread.pair).map((v) => v.label)[0] || null
    const ed = EntryDirection.filter((v) => v.value === props.thread.entryDirection).map((v) => v.label)[0] || null
    const threadLink = `/investment/thread/${props.thread.user_name}`
    const dialogOpen = ref<boolean>(false)
    const replyModalOpen = ref<boolean>(false)
    const replyComment = ref<string>('')
    const targetRepId = ref<number>()
    const overlaySrc = ref<string>()
    const overlayOpen = ref<boolean>(false)
    const handleTransition = (user_name: string) => {
      context.root.$router.push(`/investment/thread/${user_name}`)
    }
    const handleDelete = () => {
      dialogOpen.value = true
    }
    const exeDelete = () => {
      // TODO DeleteAPI
      closeDialog()
    }
    const handleEdit = () => {
      console.log('edit')
    }
    const handleReply = (id: number) => {
      targetRepId.value = id
      replyModalOpen.value = true
    }
    const handleLike = (id: number) => {
      console.log(id)
    }
    const closeDialog = () => {
      dialogOpen.value = false
    }
    const exeReply = () => {
      // reply API
      replyModalOpen.value = false
      // 成功したら下記初期化
      targetRepId.value = undefined
      replyComment.value = ''
    }
    const handleOverlay = (src: string | undefined) => {
      if (typeof src === 'undefined') {
        overlayOpen.value = false
        return
      }
      overlaySrc.value = src
      overlayOpen.value = true
    }

    const closeOverlay = () => {
      overlayOpen.value = false
    }

    onMounted(() => {
      const overlay = document.getElementsByClassName('v-overlay__scrim')
      const el = overlay[0] as Element
      el.addEventListener('click', closeOverlay, false)
    })
    const notUndefined = (target: number | string | undefined) => typeof target !== 'undefined' && target !== ''
    return {
      pair,
      ed,
      threadLink,
      dialogOpen,
      replyModalOpen,
      replyComment,
      overlayOpen,
      overlaySrc,
      notUndefined,
      handleTransition,
      handleDelete,
      handleEdit,
      handleReply,
      handleLike,
      handleOverlay,
      exeDelete,
      exeReply,
      closeDialog,
    }
  },
})
