import { reactive, toRefs } from '@vue/composition-api'
import axios from 'axios'
import AppConfig from '@/config'
import { headerWithAccessToken, refreshAccessToken } from '@/libs'
const client = axios.create({
  withCredentials: true,
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInit = () => {
  const state = reactive({
    loading: false,
    data: {
      consultation: {
        consult: [],
        location: [],
      },
      post: {
        status: [],
        type: [],
      },
      roles: [],
      thread: {
        currency_pair: [],
        entry_direction: [],
      },
      user: {
        capabilitie: [],
        status: [],
      },
    },
  })
  const fetch = async () => {
    const headers = headerWithAccessToken(AppConfig.endpoints.init.uri)
    state.loading = true
    const { uri } = AppConfig.endpoints.init
    await client
      .get(uri, {
        headers,
      })
      .then((res) => {
        refreshAccessToken(res.headers)
        state.data = res.data
      })
      .catch(() => {
        console.log('error')
      })
  }
  return {
    ...toRefs(state),
    fetch,
  }
}
