import { ref, reactive } from '@vue/composition-api'
import { Chart, ChartOptions, ChartType } from 'chart.js'
import { DatasetType } from '@/types'

// eslint-disable @typescript-eslint/explicit-module-boundary-types
export const useChart = (type: ChartType, options?: ChartOptions) => {
  const canvasRef = ref<HTMLCanvasElement | null>(null)
  const canvas = ref<CanvasRenderingContext2D | null>(null)
  const state = reactive({
    type: type,
    options: options,
  })
  // MEMO: リアクティブに入れようとすると ESLINTでおこなられるので変数にして格納する
  const graph: {
    canvas: Chart | null
  } = {
    canvas: null,
  }
  const render = (data: DatasetType | undefined): void => {
    if (data === null || canvasRef.value === null) return
    canvas.value = canvasRef.value.getContext('2d') || null
    if (canvas.value === null) return
    graph.canvas = new Chart(canvas.value, {
      type: state.type,
      data: data as DatasetType,
      options: state.options,
    })
    graph.canvas.render()
  }

  const refresh = (data: DatasetType | undefined) => {
    if (graph.canvas === null) return
    const { canvas } = graph
    canvas.destroy()
    render(data)
  }

  return {
    canvasRef,
    render,
    refresh,
  }
}
