import { reactive, toRefs } from '@vue/composition-api'
import axios, { AxiosInstance, AxiosResponse, AxiosResponseHeaders } from 'axios'
import store from '@/store'
import dayjs from 'dayjs'

const Client: AxiosInstance = axios.create({
  withCredentials: true,
})
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRequest = () => {
  const state = reactive({
    loading: false,
    data: null,
    error: undefined,
  })

  const requestFormData = async (url: string, dataName: string, file: File) => {
    state.loading = true
    const data = new FormData()
    data.append(dataName, file)

    const headers = await generateRequestHeaders(url)
    const result = await axios
      .post(url, data, {
        headers: headers,
      })
      .then((res: AxiosResponse) => {
        refreshAccessToken(res.headers)
        return res.data
      })
      .catch(() => {
        return false
      })
    return result
  }

  const request = async <T>(url: string, method: string, data: T) => {
    state.loading = true
    const headers = await generateRequestHeaders(url)
    const result = await Client.request({
      url,
      method: method as 'post' | 'get' | 'put' | 'delete',
      data,
      headers: headers,
    })
      .then(async (res: AxiosResponse) => {
        await refreshAccessToken(res.headers)
        return res.data
      })
      .catch(async (err) => {
        await refreshAccessToken(err.response.headers)
        return false
      })
    return result
  }

  const refreshAccessToken = async (responseHeaders: AxiosResponseHeaders): Promise<void> => {
    const token = responseHeaders?.access_token || false
    if (token) {
      await store.dispatch('credentials/updateAccessToken', {
        access_token: token,
        timestamp: dayjs().add(3600, 'second').unix(),
      })
    }
  }

  const generateRequestHeaders = async (url: string) => {
    const ignoreUrls = ['/api/login']
    if (ignoreUrls.includes(url)) {
      return {}
    }
    const tokens = store.getters['credentials/tokens']
    const headers = {
      Authorization: `Bearer ${tokens.access_token}`,
    }
    return headers
  }

  return {
    ...toRefs(state),
    request,
    requestFormData,
  }
}
