
















import { defineComponent, PropType } from '@vue/composition-api'

export type Props = {
  to?: string
  href?: string
  target?: string
}

export default defineComponent({
  props: {
    to: {
      type: String as PropType<Props['to']>,
      required: false,
      default: undefined,
    },
    href: {
      type: String as PropType<Props['href']>,
      required: false,
      default: undefined,
    },
    target: {
      type: String as PropType<Props['target']>,
      required: false,
      default: undefined,
    },
  },
  setup() {
    return {}
  },
})
