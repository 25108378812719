















































































































































































































import { defineComponent, ref, PropType, computed, reactive, onBeforeMount } from '@vue/composition-api'
import { PostsProps, InitResponseType } from '@/types'
import { useRequest } from '@/hooks/useRequest'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  props: {
    inputs: {
      type: Object as PropType<PostsProps>,
      requied: true,
    },
    hour: {
      type: String,
      required: true,
    },
    minute: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttnText: {
      type: String,
      required: false,
      default: '記事作成',
    },
  },
  setup(props, context) {
    const { request } = useRequest()
    const calOpen = ref<boolean>(false)
    const autoCloseDateOpen = ref<boolean>(false)
    const picker = ref<string>()
    const autoCloseDatePicker = ref('')
    const proxyInputs = computed({
      get: () => props.inputs,
      set: (val) => context.emit('update:inputs', val),
    })

    const inits = reactive<{ response: Partial<InitResponseType> }>({
      response: {
        post: {
          status: undefined,
          type: undefined,
        },
        user: {
          capabilitie: undefined,
          status: undefined,
        },
      },
    })

    const error = ref(false)
    const allUserApprovalState = ref(false)

    const hourOption: string[] = []
    const minuteOption: string[] = []
    const proxyHour = computed({
      get: () => props.hour,
      set: (val) => context.emit('update:hour', val),
    })
    const proxyMinute = computed({
      get: () => props.minute,
      set: (val) => context.emit('update:minute', val),
    })

    const createTimeOption = () => {
      for (let h = 0; h < 24; h++) {
        const hour = ('0' + h).slice(-2)
        hourOption.push(hour)
      }
      for (var d = 0; d < 60; d++) {
        const minute = ('0' + d).slice(-2)
        minuteOption.push(minute)
      }
    }

    const formatDate = (date: string) => {
      if (!date) return
      const [year, month, day] = date.split('-')
      const birth = `${year}-${month}-${day}`
      if (typeof proxyInputs.value !== 'undefined') {
        proxyInputs.value.start_date = birth
      }
      calOpen.value = false
    }

    const autoCloseDateHandler = (date: string) => {
      proxyInputs.value!.auto_close_date = date
      autoCloseDateOpen.value = false
    }

    const isRequired = computed(() =>
      proxyInputs.value?.post_type_id === 1 || proxyInputs.value?.post_type_id === 4 ? '' : 'required'
    )
    const requiredWithoutInformation = computed(() =>
      proxyInputs.value?.post_type_id === 2 || proxyInputs.value?.post_type_id === 3 ? 'required' : ''
    )

    const handleClearStartDate = () => {
      context.emit('handle-clear-start-date')
    }

    const clearAutoCloseDateHandler = () => {
      proxyInputs.value!.auto_close_date = ''
    }

    const allUserApproval = computed(() => {
      return allUserApprovalState.value === false ? 'すべての条件にチェックする' : 'すべての条件からチェックを外す'
    })

    const allUserApprovalHandler = () => {
      if (allUserApprovalState.value) {
        const { user } = inits.response
        const allStatusIds = (user?.status as { id: number; name: string }[]).map((status) => status.id)
        const allCapabilitieIds = (user?.capabilitie as { id: number; name: string }[]).map(
          (capabilitie) => capabilitie.id
        )
        proxyInputs.value!.member_status_ids = allStatusIds
        proxyInputs.value!.capabilitie_life_ids = allCapabilitieIds
        proxyInputs.value!.capabilitie_investment_ids = allCapabilitieIds
        return
      } else {
        proxyInputs.value!.member_status_ids = []
        proxyInputs.value!.capabilitie_life_ids = []
        proxyInputs.value!.capabilitie_investment_ids = []
      }
    }

    onBeforeMount(async () => {
      createTimeOption()
      const { method, uri } = context.root.$appConfig.endpoints.init
      const init = await request(uri, method, {})
      inits.response = init
    })

    const submit = async () => {
      error.value = false
      const observer = context.refs.observer as InstanceType<typeof ValidationObserver>
      if (!(await observer.validate())) {
        return false
      }
      context.emit('handle-submit')
    }

    return {
      inits,
      calOpen,
      proxyInputs,
      picker,
      hourOption,
      minuteOption,
      proxyHour,
      proxyMinute,
      formatDate,
      submit,
      handleClearStartDate,
      isRequired,
      requiredWithoutInformation,
      allUserApproval,
      allUserApprovalState,
      allUserApprovalHandler,
      autoCloseDatePicker,
      autoCloseDateHandler,
      autoCloseDateOpen,
      clearAutoCloseDateHandler,
    }
  },
})
