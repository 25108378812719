









































































































import { computed, defineComponent, onBeforeMount, PropType, reactive, ref, watch } from '@vue/composition-api'
import { ProfileProps } from '@/types'
import dayjs from 'dayjs'

type Props = Pick<
  ProfileProps,
  | 'name'
  | 'name_kana'
  | 'date_of_birth'
  | 'time_of_birth'
  | 'sex'
  | 'brithpalce'
  | 'address'
  | 'introducer'
  | 'relationship_introducer'
  | 'pr'
  | 'image_url'
>

export default defineComponent({
  props: {
    inputs: {
      type: Object as PropType<Props>,
      required: true,
      default: Object,
    },
  },
  setup(props, context) {
    const proxyInputs = computed({
      get: () => props.inputs,
      set: (val) => context.emit('update:inputs', val),
    })
    const calOpen = ref<boolean>(false)
    const picker = ref<string>()

    const formatDate = (date: string) => {
      if (!date) return
      const [year, month, day] = date.split('-')
      const birth = `${year}-${month}-${day}`
      proxyInputs.value.date_of_birth = birth
      calOpen.value = false
    }
    const proxyAvatar = ref<ArrayBuffer | string | undefined>(context.root.$appConfig.s3 + props.inputs.image_url)

    const birthTime = reactive({ hour: '00', minute: '00' })
    const sexOption = [
      { value: '男', text: '男性' },
      { value: '女', text: '女性' },
    ]
    watch(
      () => birthTime,
      (val) => {
        proxyInputs.value.time_of_birth = `${val.hour}:${val.minute}:00`
      },
      { deep: true }
    )

    const hourOption: string[] = []
    const minuteOption: string[] = []
    const calcAge = computed(() => {
      const now = dayjs()
      if (!picker.value) {
        return now.diff(proxyInputs.value.date_of_birth, 'year')
      }
      return now.diff(picker.value, 'year')
    })

    const createTimeOption = () => {
      for (let h = 0; h < 24; h++) {
        const hour = ('0' + h).slice(-2)
        hourOption.push(hour)
      }
      for (var d = 0; d < 60; d++) {
        const minute = ('0' + d).slice(-2)
        minuteOption.push(minute)
      }
    }

    const content = {
      name: {
        name: 'お名前',
        rule: 'required',
        type: 'input',
      },
      name_kana: {
        name: 'ふりがな',
        rule: 'required|nameKana',
        type: 'input',
      },
      date_of_birth: {
        name: '生年月日',
        rule: 'required',
        type: 'date',
      },
      time_of_birth: {
        name: '出生時刻',
        type: 'select',
      },
      sex: {
        name: '性別',
        rule: 'numeric',
        type: 'select',
        description:
          '※性別は生まれた時の性別をご入力下さい。<br>現在の性別と異なる場合や、LGBTXなどに該当する場合、「その他自己PR」の欄に、その旨をご記入下さい。',
      },
      brithpalce: {
        name: '生まれた場所',
        placeholder: '都道府県 日本以外の場合、国や州',
        rule: '',
        type: 'input',
      },
      address: {
        name: '現住所',
        placeholder: '都道府県 日本以外の場合、国や州',
        rule: '',
        type: 'input',
      },
      introducer: {
        name: '紹介者',
        rule: '',
        type: 'input',
      },
      relationship_introducer: {
        name: '紹介者との関係',
        rule: '',
        type: 'input',
      },
      pr: {
        name: '自己PR',
        placeholder: '全角300文字まで',
        rule: '',
        type: 'text',
      },
    }

    const handleInputClick = () => {
      const fileInput = context.refs.fileInput as HTMLInputElement[]
      fileInput[0].click()
    }

    const selectedFile = () => {
      const fileInput = context.refs.fileInput as HTMLInputElement[]
      const files = fileInput[0].files || []
      if (files.length > 0) {
        const file = files[0] as File
        context.emit('file-upload', file)
        const fileReader = new FileReader()
        // fileReader.readAsText(file)
        fileReader.onload = (e) => {
          proxyAvatar.value = e.target?.result || undefined
        }
        fileReader.readAsDataURL(file)
      }
    }

    onBeforeMount(() => {
      createTimeOption()
    })

    return {
      calcAge,
      proxyInputs,
      proxyAvatar,
      picker,
      calOpen,
      formatDate,
      hourOption,
      minuteOption,
      birthTime,
      sexOption,
      content,
      handleInputClick,
      selectedFile,
    }
  },
})
