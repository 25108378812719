



import { defineComponent, inject } from '@vue/composition-api'
import { NotificationInjectKey } from '@/hooks/useNotification'
export default defineComponent({
  setup() {
    const service = inject(NotificationInjectKey)
    const state = service?.state
    return {
      state,
    }
  },
})
