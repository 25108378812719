























































































import { defineComponent, SetupContext, PropType, reactive, computed } from '@vue/composition-api'
import { headerWithAccessToken, mergeObject } from '@/libs'
import axios from 'axios'

const client = axios.create({
  withCredentials: true,
})
export default defineComponent({
  props: {
    items: {
      type: Array as PropType<
        {
          id: number
          name: string
          url: string
        }[]
      >,
      requied: true,
      default: () => [],
    },
  },
  setup(props, ctx: SetupContext) {
    const overlayState = reactive<{ overlay: boolean; src: string }>({
      overlay: false,
      src: '',
    })
    const fileUploadState = reactive<{
      dialog: boolean
      removeItemId: number | null
      drag: boolean
      maxFileSize: number
      allowUploadFiles: number
      allowFileExtenstions: string[]
      errors: string[]
    }>({
      dialog: false,
      removeItemId: null,
      drag: false,
      maxFileSize: 5 * 1024 * 1024,
      allowUploadFiles: 3,
      allowFileExtenstions: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
      errors: [],
    })

    const uploadedFileCount = computed(() => {
      return props.items.length
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const s3UrlReplace = (item: any) => {
      const host = ctx.root.$appConfig.s3 as string
      return `${host}${item.url}`
    }
    const fileMangeEvent = {
      upload: () => {
        console.log('upload')
      },
      removeItemConfirm: (id: number) => {
        fileUploadState.dialog = true
        fileUploadState.removeItemId = id
      },
      removeItem: () => {
        fileUploadState.dialog = false
        ctx.emit('file-remove', fileUploadState.removeItemId)
      },
      overlay: (url: string) => {
        overlayState.overlay = true
        overlayState.src = url
      },
      uploadRequest: async (file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        const { uri } = ctx.root.$appConfig.endpoints.fileUpload
        const options = mergeObject(
          {
            'Content-Type': 'multipart/form-data',
          },
          headerWithAccessToken(uri)
        )
        const result = await client
          .post(uri, formData, {
            headers: options,
          })
          .then((res) => {
            return {
              result: true,
              data: res.data,
            }
          })
          .catch(() => {
            return {
              result: false,
              data: null,
            }
          })
        ctx.emit('file-upload-complete', result)
      },
      onDropEvent: (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        fileUploadState.drag = false
        const DataTransfer = e.dataTransfer as DataTransfer
        const _files = Array.from(DataTransfer.files)
        if (!_files) return false
        //ファイル数チェック
        if (uploadedFileCount.value + _files.length > fileUploadState.allowUploadFiles) {
          fileUploadState.errors.push(`アップロードできるファイル数は${fileUploadState.allowUploadFiles}個までです。`)
          return false
        }
        //ファイルサイズチェックと拡張子のチェック
        _files.forEach(async (file: File) => {
          if (!fileUploadState.allowFileExtenstions.includes(file.type)) {
            fileUploadState.errors.push('アップロードできるファイルはgif,png, jpg, jpegのみです')
            return false
          }
          if (file.size > fileUploadState.maxFileSize) {
            fileUploadState.errors.push(
              `アップロードできるファイルサイズは${fileUploadState.maxFileSize / 1024 / 1024}MBまでです`
            )
            return false
          }
          await fileMangeEvent.uploadRequest(file)
        })
      },
      onChange: (e: Event) => {
        const _files = (e.target as HTMLInputElement).files
        if (!_files) {
          return false
        }
        //ファイル数チェック
        if (uploadedFileCount.value + _files.length > fileUploadState.allowUploadFiles) {
          fileUploadState.errors.push(`アップロードできるファイル数は${fileUploadState.allowUploadFiles}個までです。`)
          return false
        }
        //ファイルサイズチェックと拡張子のチェック
        const files = Array.from(_files)
        files.forEach(async (file: File) => {
          if (!fileUploadState.allowFileExtenstions.includes(file.type)) {
            fileUploadState.errors.push('アップロードできるファイルはgif,png, jpg, jpegのみです')
            return false
          }
          if (file.size > fileUploadState.maxFileSize) {
            fileUploadState.errors.push(
              `アップロードできるファイルサイズは${fileUploadState.maxFileSize / 1024 / 1024}MBまでです`
            )
            return false
          }
          await fileMangeEvent.uploadRequest(file)
        })
      },
    }
    return {
      fileMangeEvent,
      s3UrlReplace,
      overlayState,
      fileUploadState,
      uploadedFileCount,
    }
  },
})
