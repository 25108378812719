import { reactive, onMounted, InjectionKey } from '@vue/composition-api'
import { unauthorizedNavi, normalNavi, AdminNavi } from '@/const/navigation'
import { AuthState } from '@/types'

export type useNavigationType = {
  reject: () => void
  setNavigation: (user: AuthState) => void
  navigation: {
    menus: typeof unauthorizedNavi | never[]
  }
}
export const useNavigation = () => {
  const navigation = reactive<{
    menus: typeof unauthorizedNavi
  }>({
    menus: [],
  })
  const reject = () => {
    navigation.menus = unauthorizedNavi
  }
  const setNavigation = (user: AuthState) => {
    if (user.role_id === 1) {
      navigation.menus = AdminNavi
    } else {
      navigation.menus = normalNavi
    }
  }
  onMounted(async () => {
    const data = await JSON.parse(localStorage.getItem('vuex') as string)
    const user = data?.credentials.account || false
    if (user.role_id === 1) {
      navigation.menus = AdminNavi
    } else {
      navigation.menus = normalNavi
    }
    if (user === false) {
      navigation.menus = unauthorizedNavi
      return
    }
  })
  return {
    reject,
    navigation,
    setNavigation,
  }
}

export const NavigationInjectKey: InjectionKey<useNavigationType> = Symbol('navigation')
