export default Object.freeze([
  {
    title: '基礎から応用までの教本',
    contents: [
      {
        label: '基礎知識',
        body: `bodybodybodybodybodybodybodybodybodybodybodybody<br>
          bodybodybodybodybodybodybodybodybodybodybodybody
        `,
      },
      {
        label: '講座の開設手順',
        body: `bodybodybodybodybodybodybodybodybodybodybodybody<br>
          bodybodybodybodybodybodybodybodybodybodybodybody
        `,
      },
      {
        label: 'MT4の設定と使い方',
        body: `bodybodybodybodybodybodybodybodybodybodybodybody<br>
          bodybodybodybodybodybodybodybodybodybodybodybody
        `,
      },
    ],
  },
])
