








































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { columnList } from '@/const/reportList'
import { ReportListProps, ReportListPaginateProps } from '@/types'

export default defineComponent({
  props: {
    reportList: {
      type: Array as PropType<ReportListProps[]>,
      required: false,
      default: [] as ReportListProps[],
    },
    paginate: {
      type: Object as PropType<ReportListPaginateProps>,
      required: false,
      default: () => {
        ;[]
      },
    },
  },
  setup(props, context) {
    const proxyPaginate = computed({
      get: () => props.paginate,
      set: (val) => context.emit('update:paginate', val),
    })
    return { columnList, proxyPaginate }
  },
})
