export const columnList = {
  1: [
    ['total_plus_pips', '累計獲得pips'],
    ['total_loss_pips', '累計損失pips'],
    ['max_pips', '最大獲得pips'],
    ['min_pips', '最大損失pips'],
    ['avg_pips', '平均獲得pips'],
    ['avg_holdingtime', '平均保有時間'],
    ['max_size', '最大ロット数'],
    ['min_size', '最小ロット数'],
  ],
  2: [
    ['entry_count', 'エントリー回数'],
    ['max_win', '最大連勝数'],
    ['max_loss', '最大連敗数'],
    ['max_drawdown', '最大ドローダウン'],
    ['win_rate', '勝率'],
    ['risk_reward', 'リスクリワード'],
    ['expected_value', '期待値'],
    ['profit_and_loss_total', '純損益'],
  ],
}
