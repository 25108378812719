

































































import { defineComponent, onMounted, reactive } from '@vue/composition-api'
import training from '@/const/training'
import { apiClient, formatDate, refreshAccessToken, headerWithAccessToken } from '@/libs'
export default defineComponent({
  setup(_, context) {
    const breadItems = [
      {
        text: '投資勉強会',
        disabled: false,
        href: '/investment',
      },
      {
        text: 'トレーニングルーム',
        disabled: true,
        href: '/investment',
      },
    ]
    const dataTableHeader = [
      {
        text: '投稿日時',
        value: 'created_at',
        align: 'left',
        width: '120px',
      },
      {
        text: 'タイトル',
        value: 'title',
        align: 'left',
        width: 'auto',
      },
    ]
    const posts = reactive({
      resources: {},
      loading: true,
      error: false,
    })
    const goThread = () => {
      context.root.$router.push('/investment/threads')
    }

    const goResult = () => {
      context.root.$router.push('/investment/records')
    }
    onMounted(async () => {
      posts.loading = true
      const { uri } = context.root.$appConfig.endpoints.getPosts
      const options = {
        params: {
          type: 4,
          per_page: 5,
        },
        headers: headerWithAccessToken(uri),
      }
      await apiClient
        .get(uri, options)
        .then((res) => {
          refreshAccessToken(res.headers)
          posts.resources = res.data
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          posts.error = true
        })
        .finally(() => (posts.loading = false))
    })
    return {
      breadItems,
      training,
      goThread,
      goResult,
      posts,
      dataTableHeader,
      formatDate,
    }
  },
})
