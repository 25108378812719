






















































































import { computed, defineComponent, reactive, ref, SetupContext, toRefs, inject } from '@vue/composition-api'
import CompleteComponent from '@/components/Complete.vue'
import { ValidationObserver } from 'vee-validate'
import { NotificationInjectKey } from '@/hooks/useNotification'
import axios from 'axios'

type ErrorsType = {
  [key: string]: string[]
}

export default defineComponent({
  components: {
    CompleteComponent,
  },
  setup(_, context: SetupContext) {
    const notificationService = inject(NotificationInjectKey)

    const inputs = reactive({
      email: '',
      invitation_code: '',
    })
    const state = reactive({
      loading: false,
      completed: false,
    })

    const rules = computed(() => {
      return {
        email: {
          required: true,
          email: true,
        },
        code: {
          required: true,
          min: 9,
          max: 10,
          code: true,
        },
      }
    })
    const validateUpdate = (observer: InstanceType<typeof ValidationObserver>, errors: ErrorsType) => {
      const fields: {
        [key: string]: string
      } = {
        email: 'メールアドレス',
        invitation_code: '招待コード',
      }
      Object.keys(errors).forEach((key) => {
        observer.refs[fields[key]].errors = errors[key]
      })
    }

    const submit = async () => {
      state.loading = true
      const observer = context.refs.observer as InstanceType<typeof ValidationObserver>
      if (!(await observer.validate())) {
        state.loading = false
        return false
      }
      const { uri } = context.root.$appConfig.endpoints.register
      await axios
        .post(uri, inputs)
        .then(() => {
          state.completed = true
        })
        .catch((err) => {
          const errors = err.response?.data?.errors
          if (!errors) {
            notificationService?.show('データ通信に失敗しました')
          }
          validateUpdate(observer, errors)
        })
        .finally(() => {
          state.loading = false
        })
    }

    const completeContents = ref({
      topMessage: 'ご登録のメールアドレスに確認メールをお送りいたしました。',
      bottomMessage:
        'まだ会員登録は完了しておりません。お届けしたメール内のURLをクリックして、会員登録を続けてください。',
    })

    return { ...toRefs(state), inputs, rules, submit, completeContents }
  },
})
