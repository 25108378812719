



































import { defineComponent, PropType } from '@vue/composition-api'
import { ThreadCommentType } from '@/types'
import { imageUrlReplace, formatDate, apiClient, refreshAccessToken, headerWithAccessToken } from '@/libs'
export default defineComponent({
  props: {
    comments: {
      type: Array as PropType<ThreadCommentType[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const config = ctx.root.$appConfig
    const requestLiked = async (url: string, data: { comment_id: number }) => {
      return await apiClient
        .post(url, data, {
          headers: headerWithAccessToken(url),
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          return true
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          return false
        })
    }
    const requestUnLiked = async (url: string) => {
      return await apiClient
        .delete(url, {
          headers: headerWithAccessToken(url),
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          return true
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          return false
        })
    }
    const onLiked = async (comment: ThreadCommentType) => {
      let url = ''
      if (comment.is_like) {
        // 削除
        const { uri } = config.endpoints.unLike
        url = uri.replace(':like_id', comment.id.toString())
        const response = await requestUnLiked(url)
        if (response) {
          comment.like_count -= 1
          comment.is_like = false
        }
      } else {
        // 追加
        const { uri } = config.endpoints.like
        url = uri.replace(':comment_id', comment.id.toString())
        const response = await requestLiked(url, { comment_id: comment.id })
        if (response) {
          comment.like_count++
          comment.is_like = true
        }
      }
    }
    const toTransition = (id: number) => {
      ctx.root.$router
        .push({ name: 'InvestmentThreadUser', params: { id: id.toString() } })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          return
        })
    }
    return {
      imageUrlReplace,
      formatDate,
      onLiked,
      toTransition,
    }
  },
})
