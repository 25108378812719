













































import { defineComponent, onMounted, reactive, ref, computed } from '@vue/composition-api'
import AppLink from '@/components/Parts/Link.vue'
import {
  getTypes,
  listNames,
  informationListTableHeader,
  workshopListTableHeader,
  eventListTableHeader,
} from '@/const/dashboard'
import { PostsProps } from '@/types'
import { useRequest } from '@/hooks/useRequest'
import { formatDate } from '@/libs'

export default defineComponent({
  components: {
    AppLink,
  },
  setup(_, context) {
    const overlayStates = reactive({
      show: false,
      src: '',
    })
    const type: string = context.root.$route.path.replace('/', '')
    const { request } = useRequest()
    const dataList = reactive<PostsProps[]>([])
    const post_type_id = getTypes[type]
    const breadItems = ref([
      {
        text: '勉強会・イベント申し込み',
        href: '/',
      },
      {
        text: `${listNames[type]}一覧`,
        disabled: true,
      },
    ])

    const s3ReplaceUrl = (file: { url: string }) => {
      const s3Url = context.root.$appConfig.s3
      return `${s3Url}${file?.url}`
    }

    const dataTableHeader = computed(() => {
      switch (post_type_id) {
        case 1:
        default:
          return informationListTableHeader
        case 2:
          return workshopListTableHeader
        case 3:
          return eventListTableHeader
      }
    })

    onMounted(async () => {
      const { method, uri } = context.root.$appConfig.endpoints.getPosts
      const customeUri = `${uri}?type=${post_type_id}`
      const result = await request(customeUri, method, {})
      if (result.data.length > 0) {
        for (const row of result.data) {
          const data = {
            ...row,
            id: row.id,
            title: row.title,
            detail: row.detail,
            status: row.status,
            start_date: formatDate(row.start_date, 'YYYY/MM/DD (ddd)'),
            start_time: formatDate(row.start_date, 'HH:mm'),
            created_at: row.created_at,
            actions: row.id,
          }
          dataList.push(data)
        }
      }
    })
    const checkFiles = (files: PostsProps['files']) => {
      return typeof files !== 'undefined' ? true : false
    }
    const isOpen = (date: string) => {
      const target = new Date(date)
      const now = new Date()
      return target > now ? true : false
    }
    const row_classes = (item: PostsProps) => {
      if (item.post_type_id === 1 || item.post_type_id === 4 || isOpen(item.start_date as string)) {
        return 'hover-pointer'
      }

      return 'hover-pointer grey lighten-3'
    }
    const handleToEntry = (id: number) => {
      context.root.$router.push(`/${type}/${id}`)
    }

    const handleOverlay = (src: string) => {
      overlayStates.show = !overlayStates.show
      overlayStates.src = src
    }

    const onRowClickEvent = (item: PostsProps) => {
      const { post_type_id, id } = item
      let postType = 'information'
      switch (post_type_id) {
        case 1:
        default:
          postType = 'information'
          break
        case 2:
          postType = 'workshop'
          break
        case 3:
          postType = 'events'
          break
      }
      context.root.$router.push(`/${postType}/${id}`)
    }

    const statusLabelClass = (statusId: number) => {
      if (statusId === 1) {
        return 'green--text'
      }
      return 'red--text'
    }

    return {
      breadItems,
      dataList,
      type,
      listNames,
      formatDate,
      handleToEntry,
      checkFiles,
      isOpen,
      row_classes,
      dataTableHeader,
      s3ReplaceUrl,
      handleOverlay,
      overlayStates,
      onRowClickEvent,
      statusLabelClass,
    }
  },
})
