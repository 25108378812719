


























































































import { defineComponent, reactive, watch, SetupContext, inject, ref } from '@vue/composition-api'
import Thread from '@/components/Investment/Threads/Thread.vue'
import ThreadItem from '@/components/Investment/Threads/ThreadItem.vue'
import { useThread } from '@/hooks/useThread'
import { threads } from '@/const/investment'
import { ThreadCommentType, ThreadItemType } from '@/types'
import { apiClient, refreshAccessToken, headerWithAccessToken } from '@/libs'
import { NotificationInjectKey } from '@/hooks/useNotification'
export default defineComponent({
  components: { Thread, ThreadItem },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const user = vm.$auth.user()
      const capabilitie_investment_id = user?.capabilitie_investment_id
      if (capabilitie_investment_id === 1) {
        vm.$router.push('/investment')
      }
    })
  },
  setup(_, ctx: SetupContext) {
    const config = ctx.root.$appConfig
    const breadItems = [
      {
        text: '投資勉強会',
        disabled: false,
        href: '/investment',
      },
      {
        text: 'みんなのトレード報告',
        disabled: true,
        href: '/investment/thread',
      },
    ]
    const { data, loading, remove, paginate } = useThread(true)
    const Notification = inject(NotificationInjectKey)
    const preview = reactive({
      src: '',
      visible: false,
    })
    const removeThreadState = reactive({
      visible: false,
      loading: false,
      accept: false,
    })
    const addCommentState = reactive({
      visible: false,
      comment: '',
      thread_id: 0,
      loading: false,
    })
    const removeThreadId = ref<number>(0)
    /**
     * コメントのモーダル管理
     */
    const addCommentEvent = async (thread: ThreadItemType) => {
      addCommentState.thread_id = thread.id
      addCommentState.visible = true
    }

    /**
     * 追加したコメントを挿入する
     */
    const commentAppends = (item: ThreadCommentType) => {
      data.value.data.forEach((thread) => {
        if (thread.id === item.thread_id) {
          thread.comments.push(item)
        }
      })
    }

    /**
     * スレッドの削除モーダル管理
     */
    const removeThreadEvent = (thread: ThreadItemType) => {
      removeThreadId.value = thread.id
      removeThreadState.accept = false
      removeThreadState.visible = true
    }

    /**
     * スレッドの削除を実行する
     */
    const onRemoveRequest = async () => {
      removeThreadState.loading = true
      const response = await remove(removeThreadId.value)
      if (!response) {
        Notification?.show('削除に失敗しました', 'error')
        removeThreadState.loading = false
        return
      }
      Notification?.show('削除しました', 'success')
      removeThreadState.visible = false
      data.value.data = data.value.data.filter((thread) => thread.id !== removeThreadId.value)
    }

    /**
     * コメント追加処理
     */
    const onAddCommentRequest = async () => {
      addCommentState.loading = true
      const { uri } = config.endpoints.comment
      const url = uri.replace(':id', addCommentState.thread_id.toString())
      const requestData = {
        comment: addCommentState.comment,
      }
      const res = await apiClient
        .post(url, requestData, {
          headers: headerWithAccessToken(url),
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          return res.data
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          return false
        })
        .finally(() => {
          addCommentState.loading = false
        })
      addCommentState.visible = false
      if (!res) {
        Notification?.show('コメント投稿に失敗しました', 'error')
      } else {
        commentAppends(res)
        Notification?.show('コメントを投稿しました', 'success')
      }
    }
    const onPreviewEvent = (src: string) => {
      preview.src = src
      preview.visible = true
    }

    watch(
      () => removeThreadState.visible,
      (value) => {
        if (value) {
          removeThreadState.accept = false
          removeThreadState.loading = false
        }
      }
    )
    return {
      breadItems,
      loading,
      threads,
      data,
      preview,
      onPreviewEvent,
      addCommentState,
      addCommentEvent,
      onAddCommentRequest,
      removeThreadEvent,
      removeThreadState,
      onRemoveRequest,
      paginate,
    }
  },
})
