import dayjs from 'dayjs'
import axios, { AxiosResponseHeaders } from 'axios'
import ja from 'dayjs/locale/ja'
import { SelectOptionItems } from '@/types'
import AppConfig from '@/config'
import store from '@/store'
dayjs.locale(ja)

type withAccessTokenType = {
  Authorization: string
  [key: string]: string
}
export const apiClient = axios.create({
  withCredentials: true,
})
/**
 * 1900から現在までの年を取得する
 */
export const getYearsOption = (): SelectOptionItems[] => {
  const now = dayjs()
  const start = 1900
  const end = now.year()
  const years: SelectOptionItems[] = []
  for (let i = start; i <= end; i++) {
    years.push({ value: i, text: i.toString() })
  }
  return years
}

/**
 * 月の配列をオブジェクトで返す
 */
export const getMonthsOption = (): SelectOptionItems[] => {
  const months: SelectOptionItems[] = []
  for (let i = 1; i <= 12; i++) {
    months.push({ value: i, text: i.toString() })
  }
  return months
}

/**
 * 一ヶ月の日数をオブジェクトで返す
 */
export const getDaysOption = (): SelectOptionItems[] => {
  const days: SelectOptionItems[] = []
  for (let i = 1; i <= 31; i++) {
    days.push({ value: i, text: i.toString() })
  }
  return days
}

/**
 * Date型データのフォーマットを変更
 * @param target 変更対象のDate型データ
 * @param format 変更後のフォーマット
 * @param timeSetFlg 時刻をセットするかどうか
 * @description Don't use this, use formatDate
 * @deprecated
 */
export const changeDateFormat = (target: Date, format = 'YYYY-MM-DD', timeSetFlg = false): string => {
  const date = new Date(target)
  const year = date.getFullYear().toString()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  if (timeSetFlg) {
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    return format.replace(/YYYY/, year).replace(/MM/, month).replace(/DD/, day) + ' ' + hour + ':' + minute
  } else {
    return format.replace(/YYYY/, year).replace(/MM/, month).replace(/DD/, day)
  }
}

// Date型データから時刻のみ取得
export const getTimeFromDate = (target: Date): string => {
  const date = new Date(target)
  const hour = ('0' + date.getHours()).slice(-2)
  const minute = ('0' + date.getMinutes()).slice(-2)
  const res = hour + ':' + minute
  return res
}

// RGBをランダムで生成し配列で返す
export const createRgbColors = (loop: number): string[] => {
  const colors = []
  for (let i = 0; i < loop; i++) {
    const defaultColor = { r: 256, g: 256, b: 256 }
    const r = Math.floor(Math.random() * defaultColor.r)
    const g = Math.floor(Math.random() * defaultColor.g)
    const b = Math.floor(Math.random() * defaultColor.b)
    const color = `rgb(${r}, ${g}, ${b})`
    colors.push(color)
  }
  return colors
}

/**
 * 日付を日本語の形式で表示する
 */
export const formatDate = (date: string | Date, format = 'YYYY/mm/dd'): string => {
  return dayjs(date).format(format)
}

/**
 * 現在の時間から指定された時間経過されているか判定する
 */
export const isElapsedTime = (target: string | Date, time: number): boolean => {
  const now = dayjs()
  const targetDate = dayjs(target)
  const diff = now.diff(targetDate, 'hour')
  return diff < time
}

/**
 * 値を指定の小数点以下で切り捨てる
 */
export const round = (value: number, precision = 0): number => {
  const multiplier = Math.pow(10, precision)
  return Math.round(value * multiplier) / multiplier
}

export const imageUrlReplace = (url: string): string => {
  const domain = AppConfig.s3
  return `${domain}${url}`
}

export const headerWithAccessToken = (url: string): withAccessTokenType => {
  const ignoreUrls = ['/api/login']
  if (ignoreUrls.includes(url)) {
    return {
      Authorization: '',
    }
  }
  const tokens = store.getters['credentials/tokens']
  const headers = {
    Authorization: `Bearer ${tokens.access_token}`,
  }
  return headers
}

export const refreshAccessToken = (responseHeaders: AxiosResponseHeaders): void => {
  const token = responseHeaders?.access_token || false
  if (token) {
    store.dispatch('credentials/updateAccessToken', {
      access_token: token,
      timestamp: dayjs().add(3600, 'second').unix(),
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeObject = (...args: any[]): any => {
  return Object.assign({}, ...args)
}
