















































import { defineComponent, PropType } from '@vue/composition-api'
import LinkComponent from '@/components/Parts/Link.vue'
import { Reply as ReplyProps } from '@/const/investment'

export default defineComponent({
  components: { LinkComponent },
  props: {
    replies: {
      type: Array as PropType<ReplyProps[]>,
      required: true,
    },
  },
  setup(_, context) {
    const handleLike = (id: number) => {
      context.emit('handle-like', id)
    }
    const handleTransition = (user_name: string) => {
      context.root.$router.push(`/investment/thread/${user_name}`)
    }
    return { handleLike, handleTransition }
  },
})
