




import { defineComponent, onMounted, PropType, reactive, ref, watch } from '@vue/composition-api'
import Chart from '@/components/Chart.vue'
import { DatasetType, HorizonBarResponseProps } from '@/types'
import * as chartjs from 'chart.js'
import { colors } from '@/const/colorPalette'

export default defineComponent({
  components: {
    Chart,
  },
  props: {
    results: {
      type: Object as PropType<HorizonBarResponseProps>,
      required: true,
    },
  },
  setup(props) {
    const chartData = reactive<Partial<DatasetType>>({
      labels: undefined,
      datasets: [],
    })
    const options = reactive<Partial<chartjs.ChartOptions>>({})

    const dataset = reactive<{ labels: string[]; plus: number[]; loss: number[] }>({
      labels: [],
      plus: [],
      loss: [],
    })

    const setData = (target: HorizonBarResponseProps) => {
      if (typeof target.data === 'undefined') return
      dataset.labels = target.data.plus.map((v) => v.x)
      dataset.plus = target.data.plus.map((v) => v.y)
      dataset.loss = target.data.loss.map((v) => v.y)
      chartData.labels = dataset.labels
      chartData.datasets?.push({
        label: '損失',
        data: dataset.loss,
        backgroundColor: colors[4]['loss' as keyof typeof colors[4]],
      })
      chartData.datasets?.push({
        label: '利益',
        data: dataset.plus,
        backgroundColor: colors[4]['plus' as keyof typeof colors[4]],
      })
    }

    const setOptions = () => {
      options.responsive = true
      options.maintainAspectRatio = false
      options.indexAxis = 'y'
      options.datasets = {
        bar: {
          categoryPercentage: 0.6,
        },
      }
      options.scales = {
        xAes: {
          stacked: true,
        },
        yAes: {
          stacked: true,
        },
      }
    }

    const proxyResult = ref<HorizonBarResponseProps>(props.results)

    watch(
      () => props.results,
      (val) => {
        chartData.datasets?.splice(-chartData.datasets.length)
        setTimeout(() => {
          setData(val)
          setOptions()
        }, 300)
      }
    )

    onMounted(() => {
      setTimeout(() => {
        setData(proxyResult.value)
        setOptions()
      }, 300)
    })

    return { chartData, options }
  },
})
