






















import { defineComponent, reactive, inject, ref, onMounted, computed } from '@vue/composition-api'
import { useRequest } from '@/hooks/useRequest'
import AppPostsForm from '@/components/Posts/Form.vue'
import FileUpload from '@/components/Posts/FileUpload.vue'
import { PostsProps, CapabilityItemType } from '@/types'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { changeDateFormat, getTimeFromDate } from '@/libs'
/* eslint-disable @typescript-eslint/no-explicit-any */
type FileUploadResponseType = {
  result: boolean
  data: {
    id: number
    message: string
    url: string
    upload: boolean
  }
}
export default defineComponent({
  components: {
    AppPostsForm,
    FileUpload,
  },
  setup(_, context) {
    const { request } = useRequest()
    const method = ref('post')
    const targetUri = ref()
    const id = computed(() => (context.root.$route.params.id !== 'add' ? context.root.$route.params.id : undefined))
    const isEdit = computed(() => typeof id.value !== 'undefined')
    const notificationService = inject(NotificationInjectKey)
    const inputs = reactive<PostsProps>({
      title: '',
      detail: '',
      start_date: '',
      location: '',
      capacity: 0,
      post_type_id: undefined,
      status_id: undefined,
      status: [] as number[],
      member_status_ids: [],
      capabilitie_life_ids: [],
      capabilitie_investment_ids: [],
      files: [],
      file_ids: [],
      join_condition: '',
      auto_close_date: '',
    })
    const saveBtnText = ref('記事作成')
    const hour = ref('09')
    const minute = ref('00')

    const getStartDatetime = () => {
      if (inputs.post_type_id === 1) {
        if (inputs.start_date === '' || inputs.start_date === null) {
          return ''
        } else {
          return `${inputs.start_date} ${hour.value}:${minute.value}:00`
        }
      } else {
        return `${inputs.start_date} ${hour.value}:${minute.value}:00`
      }
    }

    const handleClearStartDate = () => {
      inputs.start_date = ''
    }

    //保存処理
    const handleSubmit = async () => {
      const params = {
        title: inputs.title,
        detail: inputs.detail,
        post_type_id: inputs.post_type_id,
        status_id: inputs.status_id,
        start_date: getStartDatetime(),
        location: inputs.location,
        capacity: inputs.capacity,
        member_status_ids: inputs.member_status_ids,
        capabilitie_life_ids: inputs.capabilitie_life_ids,
        capabilitie_investment_ids: inputs.capabilitie_investment_ids,
        file_ids: inputs.file_ids,
        join_condition: inputs.join_condition,
        auto_close_date: inputs.auto_close_date,
      }

      const { uri } = context.root.$appConfig.endpoints.postsAdd
      if (id.value) {
        method.value = 'put'
        targetUri.value = `${uri}/${id.value}`
      } else {
        targetUri.value = uri
      }
      const result = await request(targetUri.value, method.value, params)
      if (result === false) {
        notificationService?.show('データ通信に失敗しました', 'error')
      } else {
        notificationService?.show('登録が完了しました。', 'success')
        context.root.$router.push(`/admin`)
      }
    }

    // ファイルアップロード
    const fileUploadCompleteHandler = (value: FileUploadResponseType) => {
      if (!value.result) {
        notificationService?.show('ファイルのアップロードに失敗しました。', 'error')
        return false
      }
      inputs.file_ids?.push(value.data.id)
      inputs.files?.push(value.data)
      notificationService?.show('ファイルのアップロードが完了しました。', 'success')
    }
    // ファイル削除
    const fileRemoveHandler = (value: number) => {
      const response = {
        message: '指定のファイルを削除しました。',
        type: 'success',
      }
      try {
        inputs.file_ids = inputs.file_ids?.filter((item: number) => item !== value)
        inputs.files = inputs.files?.filter((item: any) => item.id !== value)
      } catch {
        response.message = 'ファイルの削除に失敗しました。'
        response.type = 'error'
      }
      notificationService?.show(response.message, response.type)
    }

    onMounted(async () => {
      if (id.value) {
        saveBtnText.value = '記事更新'
        const { method, uri } = context.root.$appConfig.endpoints.getPosts
        const customeUri = `${uri}/${id.value}`
        const result = await request(customeUri, method, {})
        inputs.join_condition = result.join_condition
        inputs.title = result.title
        inputs.detail = result.detail
        inputs.post_type_id = result.post_type_id
        inputs.start_date = changeDateFormat(result.start_date)
        inputs.start_time = getTimeFromDate(result.start_date)
        inputs.status_id = result.status_id
        inputs.location = result.location
        inputs.capacity = result.capacity
        inputs.member_status_ids = result.capability_member_status.map(
          (item: CapabilityItemType) => item.member_status_id
        )
        inputs.capabilitie_life_ids = result.capability_life.map((item: CapabilityItemType) => item.capabilitie_life_id)
        inputs.capabilitie_investment_ids = result.capability_investment.map(
          (item: CapabilityItemType) => item.capabilitie_investment_id
        )
        inputs.files = result.files
        inputs.file_ids = result.files.map((item: any) => item.id)
        hour.value = inputs.start_time.substr(0, 2)
        minute.value = inputs.start_time.substr(3, 2)
        inputs.auto_close_date = result.auto_close_date
      }
    })

    return {
      isEdit,
      inputs,
      hour,
      minute,
      saveBtnText,
      handleClearStartDate,
      handleSubmit,
      fileUploadCompleteHandler,
      fileRemoveHandler,
    }
  },
})
