


























































































































import { computed, defineComponent, reactive, ref, SetupContext, watch } from '@vue/composition-api'
import ErrorComponent from '@/components/Error.vue'
import LinkComponent from '@/components/Parts/Link.vue'
import { ValidationObserver } from 'vee-validate'
import { getYearsOption, getMonthsOption, getDaysOption } from '@/libs'
export default defineComponent({
  name: 'RegistrationInput',
  components: {
    ErrorComponent,
    LinkComponent,
  },
  props: {
    inputs: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx: SetupContext) {
    const brithDayState = reactive({
      values: {
        year: 1990,
        month: 1,
        day: 1,
      },
      options: {
        years: getYearsOption(),
        months: getMonthsOption(),
        days: getDaysOption(),
      },
    })
    const notAgree = ref<boolean>(false)
    const errorFlg = ref<boolean>(false)

    const menu = ref<boolean>(false)
    const birth = ref<string>()
    const picker = ref<string>()

    const proxyInputs = computed({
      get: () => props.inputs,
      set: (val) => ctx.emit('update:inputs', val),
    })

    const inputContent = {
      user_name: {
        key: 'username',
        name: 'ユーザー名',
        rule: 'required|max:16|min:2',
        type: 'input',
        align: 'start',
        description:
          '※ユーザー名は全ての会員に表示されるので、ニックネーム等、本名以外でご利用になることをお勧めいたします。<br>16文字以内の全角、半角でお決めになってください。また、他の方と重複した場合はご利用になれません。<br>Discordのサロンメンバーになっている方は、そちらと同一のユーザー名をご使用下さい。',
      },
      email: {
        key: 'email',
        name: 'メールアドレス',
        type: 'text',
        align: 'center',
      },
      password: {
        key: 'password',
        name: 'パスワード',
        rule: 'required|min:8|max:16|password',
        type: 'input',
        align: 'center',
        hint: '※パスワードは、半角英数8文字以上16文字以内',
      },
      passwordConfirm: {
        key: 'passwordConfirm',
        name: 'パスワード(確認)',
        rule: 'required|confirmed:パスワード',
        type: 'input',
        align: 'center',
      },
      name: {
        key: 'name',
        name: 'お名前',
        rule: 'required',
        type: 'input',
        align: 'center',
      },
      nameKana: {
        key: 'name_kana',
        name: 'ふりがな',
        rule: 'required|nameKana',
        type: 'input',
        align: 'center',
      },
      // birthday: {
      //   key: 'birthday',
      //   name: '生年月日',
      //   rule: 'numeric',
      //   type: 'birthday',
      //   align: 'center',
      // },
    }

    const onChange = () => {
      const { year, month, day } = brithDayState.values
      const birthDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
      proxyInputs.value.date_of_birth = birthDate
    }
    const submit = async () => {
      const observer = ctx.refs.observer as InstanceType<typeof ValidationObserver>
      if (!(await observer.validate()) || !proxyInputs.value.agree) {
        errorFlg.value = true
        notAgree.value = true
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      ctx.emit('handle-submit')
    }

    watch(
      () => proxyInputs.value.agree,
      (val) => {
        notAgree.value = !val
      }
    )

    return {
      brithDayState,
      proxyInputs,
      inputContent,
      notAgree,
      errorFlg,
      submit,
      onChange,
      menu,
      birth,
      picker,
    }
  },
})
