































import { defineComponent, ref, computed, watch, SetupContext } from '@vue/composition-api'
import axios from 'axios'
import { headerWithAccessToken, refreshAccessToken, mergeObject } from '@/libs'
const client = axios.create({
  withCredentials: true,
})
export default defineComponent({
  props: {
    files: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx: SetupContext) {
    const drag = ref(false)
    const errors = ref('')
    const maxSize = ref(5 * 1024 * 1024)
    const allowuploadFiles = ref(3)
    const allowFileExtensions = ref(['image/png', 'image/jpg', 'image/jpeg', 'image/gif'])
    const uploadDisabeled = computed(() => {
      return props.files >= allowuploadFiles.value
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validateMaxFiles = (files: any): boolean => {
      if (files.length > allowuploadFiles.value) {
        errors.value = `アップロードできるファイルは${allowuploadFiles.value}つまでです`
        return false
      } else {
        errors.value = ''
      }
      return true
    }
    const onChange = (e: Event) => {
      const _files = (e.target as HTMLInputElement).files
      if (!_files) {
        return false
      }
      if (props.files + _files.length > allowuploadFiles.value) {
        errors.value = `アップロードできるファイルは${allowuploadFiles.value}つまでです。`
        return false
      }
      if (!validateMaxFiles(_files)) {
        return false
      }
      const files = Array.from(_files)
      files.forEach(async (file: File) => {
        if (file.size > maxSize.value) {
          errors.value = `ファイルサイズは${maxSize.value / 1024 / 1024}MBまでです`
          return false
        }
        await uploadRequest(file)
      })
    }

    const uploadRequest = async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      const { uri } = ctx.root.$appConfig.endpoints.fileUpload
      const options = mergeObject(
        {
          'Content-Type': 'multipart/form-data',
        },
        headerWithAccessToken(uri)
      )
      await client
        .post(uri, formData, {
          headers: options,
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          ctx.emit('success', res.data)
        })
        .catch(() => {
          errors.value = 'アップロードに失敗しました'
        })
    }

    const onDrop = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      drag.value = false
      const DataTransfer = e.dataTransfer as DataTransfer
      const _files = Array.from(DataTransfer.files)
      if (!_files) {
        return false
      }
      if (props.files + _files.length > allowuploadFiles.value) {
        errors.value = `アップロードできるファイルは${allowuploadFiles.value}つまでです。`
        return false
      }
      if (!validateMaxFiles(_files)) {
        return false
      }
      _files.forEach(async (file: File) => {
        if (file.size > maxSize.value) {
          errors.value = `ファイルサイズは${maxSize.value / 1024 / 1024}MBまでです`
          return false
        }
        if (!allowFileExtensions.value.includes(file.type)) {
          errors.value = 'アップロードできるファイルはgif,png, jpg, jpegのみです'
          return false
        }
        await uploadRequest(file)
      })
    }

    watch(
      () => props.files,
      (files) => {
        if (files > allowuploadFiles.value) {
          errors.value = 'ファイルは3つまでアップロードできます'
        }
      }
    )
    return {
      drag,
      onDrop,
      onChange,
      errors,
      uploadDisabeled,
    }
  },
})
