import Vue from 'vue'

import { ValidationObserver, ValidationProvider, configure, extend as veeExtend, localize } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
import { required, max, min, email, confirmed, numeric } from 'vee-validate/dist/rules'
const config = {
  bails: false,
  mode: 'aggressive',
}

configure(config)
veeExtend('required', required)
veeExtend('max', max)
veeExtend('min', min)
veeExtend('email', email)
veeExtend('confirmed', confirmed)
veeExtend('numeric', numeric)
veeExtend('code', {
  message: () => '招待コードは、半角大文字、小文字、数字の組み合わせで最大18文字で入力してください',
  validate: (value: string | undefined) => {
    // TODO: 使用が決まっていないため英数字はチェックしない
    if (typeof value === 'undefined') return false
    // if (!value.match(/\d/)) return false
    // if (!value.match(/[a-z]/)) return false
    // if (!value.match(/[A-Z]/)) return false
    return true
  },
})
veeExtend('password', {
  message: () => 'パスワードは半角英数字もしくは_-~の記号で入力してください',
  validate: (value: string | undefined) => {
    if (typeof value === 'undefined') return false
    // if (!value.match(/\d/)) return false
    if (!value.match(/^[A-Za-z0-9!-_~]*$/)) return false
    return true
  },
})
veeExtend('nameKana', {
  message: () => 'ひらがなのみで入力してください',
  validate: (value: string | undefined) => {
    if (typeof value === 'undefined') return false
    if (!value.match(/^[ぁ-んー\u3000]*$/)) return false
    return true
  },
})
veeExtend('agree', {
  message: () => 'ポリシーを確認の上、同意にチェックをしてください',
  validate: (value: boolean) => {
    console.log(value)
    if (!value) return false
    return true
  },
})
localize('ja', ja)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
