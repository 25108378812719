























import { computed, defineComponent, ref } from '@vue/composition-api'
import { validate } from 'vee-validate'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    replyComment: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const proxyOpen = computed({
      get: () => props.open,
      set: (val) => context.emit('update:open', val),
    })
    const proxyReplyComment = computed({
      get: () => props.replyComment,
      set: (val) => context.emit('update:reply-comment', val),
    })
    const error = ref<string>()

    const handleReply = async () => {
      const res = await validate(proxyReplyComment.value, 'required', { name: '返信コメント' })
      if (res.errors.length > 0) {
        error.value = res.errors[0]
      } else {
        context.emit('handle-reply')
      }
    }
    const handleClose = () => {
      proxyOpen.value = false
      proxyReplyComment.value = ''
    }
    return { proxyOpen, proxyReplyComment, error, handleReply, handleClose }
  },
})
