














































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    inputs: {
      type: Object,
      reqired: true,
    },
  },
  setup(_, { emit }) {
    const inputContent = {
      user_name: {
        key: 'username',
        name: 'ユーザー名',
      },
      email: {
        key: 'email',
        name: 'メールアドレス',
      },
      password: {
        key: 'password',
        name: 'パスワード',
      },
      name: {
        key: 'name',
        name: 'お名前',
      },
      nameKana: {
        key: 'name_kana',
        name: 'ふりがな',
      },
      birthday: {
        key: 'date_of_birth',
        name: '生年月日',
        type: 'birthday',
      },
    }

    const modify = () => {
      emit('handle-modify')
    }
    const complete = () => {
      emit('handle-complete')
    }
    return { inputContent, modify, complete }
  },
})
