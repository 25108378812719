





































































































































import { computed, defineComponent, inject, onBeforeMount, onMounted, reactive, ref } from '@vue/composition-api'
import LinkComponent from '@/components/Parts/Link.vue'
import { listNames } from '@/const/dashboard'
import { useRequest } from '@/hooks/useRequest'
import { PostsProps } from '@/types'
import { formatDate } from '@/libs'
import { NotificationInjectKey } from '@/hooks/useNotification'
import PaymentCancelPolicy from '@/components/PaymentCancelPolicy.vue'
export default defineComponent({
  components: {
    LinkComponent,
    PaymentCancelPolicy,
  },
  setup(_, context) {
    const notificationService = inject(NotificationInjectKey)
    const { request } = useRequest()
    const id = Number(context.root.$route.params.id)
    const type: string = context.root.$route.path.replace(/\//g, '').replace(String(id), '')
    const user = computed(() => context.root.$auth.user())
    const paymentCacnelPolicyState = reactive<{
      show: boolean
      mode: 'cancel' | 'payment'
    }>({
      show: false,
      mode: 'payment',
    })
    const breadItems = ref([
      {
        text: '勉強会・イベント申し込み',
        disabled: false,
        href: '/',
      },
      {
        text: `${listNames[type]}一覧`,
        disabled: false,
        href: `/${type}`,
      },
      {
        text: `${listNames[type]}詳細`,
        disabled: true,
        href: `/${type}/${id}`,
      },
    ])

    const applyFlg = ref<boolean>(false)

    const overlaySrc = ref<string>()
    const overlayOpen = ref<boolean>(false)

    const details = reactive<{ data: PostsProps }>({
      data: {
        id: undefined,
        title: undefined,
        detail: undefined,
        start_date: undefined,
        capacity: undefined,
        location: undefined,
        post_type_id: undefined,
        status_id: undefined,
        files: undefined,
        status: undefined,
        member_status_ids: [],
        capabilitie_life_ids: [],
        capabilitie_investment_ids: [],
        created_at: undefined,
      },
    })

    const onPaymentCancelPolicyDialogOpen = (mode: 'payment' | 'cancel') => {
      paymentCacnelPolicyState.show = true
      paymentCacnelPolicyState.mode = mode
    }
    const onPaymentCancelPolicyDialogClose = (value: boolean) => {
      paymentCacnelPolicyState.show = value
    }

    const isOpen = (date: string) => {
      const target = new Date(date)
      const now = new Date()
      return target > now ? true : false
    }
    const checkFiles = (files: PostsProps['files']) => {
      return typeof files !== 'undefined' ? true : false
    }

    const handleOverlay = (src: string | undefined) => {
      if (typeof src === 'undefined') {
        overlayOpen.value = false
        return
      }
      overlaySrc.value = src
      overlayOpen.value = true
    }

    const closeOverlay = () => {
      overlayOpen.value = false
    }

    const statusLabelClass = computed(() => {
      const statusId = details.data.status_id
      switch (statusId) {
        case 1:
          return 'green--text font-weight-bold'
        case 2:
          return 'red--text font-weight-bold'
      }
      return ''
    })

    /**
     * 申し込む
     */
    const handleApply = async () => {
      const method = context.root.$appConfig.endpoints.postsJoin.method
      const uri = context.root.$appConfig.endpoints.postsJoin.uri.replace(':id', String(id))
      const result = await request(uri, method, {})
      if (result === false) {
        notificationService?.show('データ通信に失敗しました', 'error')
      } else {
        applyFlg.value = true
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }

    const isInformation = computed(() => type === 'information' || type === 'investment-information')

    const s3ReplaceUrl = (file: { url: string }) => {
      const s3Url = context.root.$appConfig.s3
      return `${s3Url}${file?.url}`
    }

    const theState = computed(() => {
      const postId = details.data.post_type_id
      const res = {
        message: '',
        show: false,
        color: '',
      }
      if (postId === 1 || details.data.status_id === undefined) {
        return res
      }
      res.show = true
      res.message = details.data.status_id === 1 ? '募集中' : '締切'
      res.color = details.data.status_id === 1 ? 'success' : 'error'
      return res
    })

    onBeforeMount(() => {
      if (typeof id === 'undefined') {
        context.root.$router.push('/')
      }
    })
    onMounted(async () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      const { method, uri } = context.root.$appConfig.endpoints.getPosts
      const customeUri = `${uri}/${id}`
      const result = await request(customeUri, method, { id: id })
      details.data = result
      const breadItemsLastChild = breadItems.value[breadItems.value.length - 1]
      breadItemsLastChild.text = result.title
      const overlay = document.getElementsByClassName('v-overlay__scrim')
      const el = overlay[0] as Element
      el.addEventListener('click', closeOverlay, false)
    })
    return {
      breadItems,
      type,
      user,
      overlayOpen,
      overlaySrc,
      applyFlg,
      details,
      formatDate,
      handleOverlay,
      closeOverlay,
      isOpen,
      checkFiles,
      handleApply,
      isInformation,
      paymentCacnelPolicyState,
      onPaymentCancelPolicyDialogOpen,
      onPaymentCancelPolicyDialogClose,
      s3ReplaceUrl,
      theState,
      statusLabelClass,
    }
  },
})
