




import { defineComponent, onMounted, PropType, reactive, ref, watch } from '@vue/composition-api'
import Chart from '@/components/Chart.vue'
import { DatasetType, BarResponseProps } from '@/types'
import * as chartjs from 'chart.js'
import { colors } from '@/const/colorPalette'

export default defineComponent({
  components: {
    Chart,
  },
  props: {
    results: {
      type: Object as PropType<BarResponseProps>,
      required: true,
    },
    graphType: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const chartData = reactive<Partial<DatasetType>>({
      labels: undefined,
      datasets: [],
    })
    const options = reactive<chartjs.ChartOptions>({})
    const data = ref<number[]>()

    const setData = (target: BarResponseProps) => {
      if (typeof target.data === 'undefined') return
      data.value = target.data.map((v) => v.y)
      chartData.labels = target.data.map((v) => v.x)
      const backgroundColor = Object.keys(target.data).map((key) => {
        return colors[props.graphType][key as keyof typeof colors[typeof props.graphType]]
      })
      chartData.datasets?.push({
        data: data.value,
        label: target.label,
        backgroundColor: backgroundColor,
      })
    }

    const setOptions = (title: string) => {
      options.responsive = true
      options.maintainAspectRatio = true
      const titleObj = {
        display: false,
        text: title,
      }
      options.scales = {
        x: {
          position: 'bottom',
        },
        ...options.scales,
      }
      options.plugins = {
        legend: {
          display: false,
        },
        title: titleObj,
      }
      options.datasets = {
        bar: {
          categoryPercentage: 0.6,
        },
      }
    }

    const proxyResult = ref<BarResponseProps>(props.results)
    watch(
      () => props.results,
      (val) => {
        setTimeout(() => {
          chartData.datasets?.splice(-chartData.datasets.length)
          setData(val)
          setOptions(val.label)
        }, 300)
      }
    ),
      { deep: true }

    onMounted(() => {
      setTimeout(() => {
        setData(proxyResult.value)
        setOptions(proxyResult.value.label)
      }, 300)
    })

    return { chartData, options }
  },
})
