

























import { defineComponent } from '@vue/composition-api'
import faqs from '../const/faqs'

export default defineComponent({
  name: 'Faqs',
  setup() {
    return { faqs }
  },
})
