






import { computed, defineComponent, PropType } from '@vue/composition-api'
import { StatisticsListProps } from '@/types'

export default defineComponent({
  props: {
    results: {
      type: Object as PropType<StatisticsListProps>,
      required: false,
      default: () => {
        ;[]
      },
    },
  },
  setup(props) {
    const headers = [
      {
        text: '通貨ペア',
        value: 'currency_pair',
      },
      {
        text: '平均獲得pips',
        value: 'pips_avg',
      },
      {
        text: '平均保有時間',
        value: 'holdingtime_avg',
      },
      {
        text: 'エントリー回数',
        value: 'entry_count',
      },
      {
        text: '勝率',
        value: 'win_rate',
      },
      {
        text: 'リスクリワード',
        value: 'risk_reward',
      },
      {
        text: '期待値',
        value: 'expected_value',
      },
      {
        text: '純損益計',
        value: 'profit_and_loss_total',
      },
    ]
    const result = computed<StatisticsListProps['data']>(() => props.results.data)

    return { headers, result }
  },
})
