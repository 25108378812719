export default Object.freeze([
  {
    title: '新規登録をしても、確認メールが届きません。',
    description: `
      <div style="margin-bottom: 10px">
        下記の可能性が考えられますので、ご確認下さい。<br />
        ・メールアドレスを間違って登録した場合<br />
        登録する際にはメールアドレスの入力にご注意ください。メールアドレスは全角英数字を使用せず、必ず半角英数字で入力してください。
      </div>
      <div style="margin-bottom: 10px">
        ・迷惑メール対策をしている場合
        ドメイン指定受信などの迷惑メール対策をしている方は､「@yuzenfamily.com」からのメールが受信できるように設定を再確認してください｡<br />
        使用環境や通信会社等によって迷惑メール設定が異なります。詳細は各ページをご確認ください。
      </div>
      <div style="margin-bottom: 20px">
        docomoの迷惑メール設定は<a href="https://www.nttdocomo.co.jp/info/spam_mail/index.html"
          >こちら(https://www.nttdocomo.co.jp/info/spam_mail/index.html)</a
        ><br />
        auの迷惑メール設定は<a href="https://www.au.com/support/service/mobile/trouble/mail/email/filter/"
          >こちら(https://www.au.com/support/service/mobile/trouble/mail/email/filter/)</a
        ><br />
        SoftBankの迷惑メール設定は<a href="https://www.softbank.jp/mobile/support/mail/antispam/howto/"
          >こちら(https://www.softbank.jp/mobile/support/mail/antispam/howto/)</a
        ><br />
        outlook等のメーラーをお使いの場合は、そちらの設定もご確認いただきますようお願いいたします。<br />
      </div>
    `,
  },
  {
    title: '「招待コード」がわかりません。',
    description: `
      <div>
        YUZEN.comは、<span style="text-decoration: underline">完全招待制のオンラインサロン</span
        >となっております。そのため、新規登録時に必要な招待コードは、既存会員様から発行してもらう必要
        があります。既存会員様なら、どの方でも招待コードを発行可能ですので、お友達に会員様がいらっしゃる場合は、そちらでお申し出下さい。
      </div>
    `,
  },
  {
    title: '「メールアドレスが既に登録されています」と表示され、登録できません。',
    description: `
    <div>
    ご入力いただいたメールアドレスで既に登録がある場合のエラーです。過去に会員登録済の方は、新規登録ではなくログインをしてください。
    </div>
    `,
  },
  {
    title: 'ログインができません。',
    description: `
      <div style="margin-bottom: 10px">
        ログインができない場合、以下の可能性が考えられます。それぞれの対応をお試しください。<br />
        ・どのメールアドレスで登録したのか、忘れてしまった場合<br />
        <div style="text-indent: 1em">
        <a href="/nickname-login">こちら</a>から登録ユーザー名、登録生年月日、パスワードの3つを利用してログインが可能です。
        </div>
        <div style="text-indent: 1em">ログイン後、会員情報確認・変更ページにて、登録メールアドレスをご確認下さい。</div>
      </div>
      <div style="margin-bottom: 10px">
        ・パスワードが不明な場合
        <div style="text-indent: 1em"><a href="/password/forgot">こちら</a>から、メール認証によるパスワードリセットをお試しください。</div>
      </div>
      <div style="margin-bottom: 10px">
        ・メールアドレスもパスワードも不明な場合
        <div style="text-indent: 1em">ご本人様確認が取れないため、改めて新規会員登録をお願いいたします。</div>
      </div>
    `,
  },
])
