import { ColorsProps } from '@/types'
export const colors = {
  // トレード結果推移
  1: {
    //日毎純損益
    account_balance: '#42A5F5',
    // 累積純損益
    cumulative: '#FB8C00',
    // 口座残高
    daily: '#4CAF50',
  },
  // 時間帯別損益合算
  2: {
    0: '#01579B', // Tokyo
    1: '#B39DDB', // London
    2: '#4CAF50', // NY1
    3: '#FF9800', // NY2
  },
  // 曜日別損益合算
  3: {
    0: '#FDD835', // 日曜日
    1: '#E53935', // 月曜日
    2: '#1E88E5', // 火曜日
    3: '#43A047', // 水曜日
    4: '#5E35B1', // 木曜日
    5: '#C0CA33', // 金曜日
    6: '#8E24AA', // 土曜日
  },
  // 通貨ペア別損益比率
  4: { loss: '#0288D1', plus: '#FFA726' },
} as ColorsProps
