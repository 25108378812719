import { ProfileProps } from './userData'

export type Props = {
  pair?: number
  entryDirection?: number
  entry?: number
  exit?: number
  tp?: string | number
  sl?: string | number
  entryDatetime?: string
  comment?: string
  files?: { [key: number]: string | undefined | File }
}

type SelectOption = {
  value: number
  label: string
}

export const Pair: SelectOption[] = [
  {
    value: 1,
    label: 'USDJPY',
  },
  {
    value: 2,
    label: 'EURJPY',
  },
  {
    value: 3,
    label: 'EURUSD',
  },
  {
    value: 4,
    label: 'EURAUD',
  },
  {
    value: 5,
    label: 'EURNZD',
  },
  {
    value: 6,
    label: 'GBPJPY',
  },
  {
    value: 7,
    label: 'GBPUSD',
  },
  {
    value: 8,
    label: 'GBPAUD',
  },
  {
    value: 9,
    label: 'GBPNZD',
  },
  {
    value: 10,
    label: 'AUDJPY',
  },
  {
    value: 11,
    label: 'AUDUSD',
  },
  {
    value: 12,
    label: 'NZDJPY',
  },
  {
    value: 13,
    label: 'NZDUSD',
  },
  {
    value: 14,
    label: 'XAUUSD',
  },
  {
    value: 15,
    label: 'XAGUSD',
  },
  {
    value: 16,
    label: 'BTCUSD',
  },
  {
    value: 17,
    label: 'ETHUSD',
  },
  {
    value: 18,
    label: 'XRPUSD',
  },
]

export const EntryDirection: SelectOption[] = [
  {
    value: 1,
    label: 'Buy',
  },
  {
    value: 2,
    label: 'Sell',
  },
]

export type Reply = {
  id: number
  user_name: string
  avatar?: string
  body: string
  createdAt: Date
  like: number
}

export type ThreadProps = Props &
  Pick<ProfileProps, 'avatar' | 'user_name'> & { id: number; createdAt: Date; like: number; reply?: Reply[] }

export const threads: ThreadProps[] = [
  {
    id: 1,
    user_name: 'Yusen',
    avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
    createdAt: new Date(2021, 2, 7),
    pair: 1,
    entryDirection: 1,
    entry: 22.3,
    exit: 1.42,
    tp: undefined,
    sl: undefined,
    entryDatetime: '2021-2-6 9:29:39',
    comment: `経済成長しながらデフレ脱却するなら簡単なのは消費税ゼロ。
岸田氏じゃ出来ないから違う方法でやろうとしていると判断します。
建設国債なら分かるが赤字国債は簡単に発行出来ないから揉めてんでしょ。
揉めたら良い、それが議論。`,
    files: {
      1: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
      2: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
      3: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
    },
    reply: [
      {
        id: 10,
        user_name: 'TestD',
        avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
        body: `自民党に入れなかった身としては、入れた人の気持ちが知りたい。
利権で食ってて政権交代したら一家で路頭に迷うような人が、そんなに沢山いるとは思えないのだけど…GoToに関わる業者とかの間接的な影響も含めたら結構いる？`,
        createdAt: new Date(),
        like: 2010,
      },
      {
        id: 11,
        user_name: 'XD',
        avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
        body: 'jfoiajefoiae',
        createdAt: new Date(),
        like: 10,
      },
    ],
    like: 2,
  },
  {
    id: 2,
    user_name: 'TestD',
    avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
    createdAt: new Date(2021, 2, 7),
    pair: 1,
    entryDirection: 1,
    entry: 22.3,
    exit: 1.42,
    tp: undefined,
    sl: undefined,
    entryDatetime: '2021-2-6 9:29:39',
    comment: `経済成長しながらデフレ脱却するなら簡単なのは消費税ゼロ。
岸田氏じゃ出来ないから違う方法でやろうとしていると判断します。
建設国債なら分かるが赤字国債は簡単に発行出来ないから揉めてんでしょ。
揉めたら良い、それが議論。`,
    files: {
      1: '',
      2: '',
      3: '',
    },
    reply: [],
    like: 2,
  },
  {
    id: 3,
    user_name: 'XD',
    avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
    createdAt: new Date(2021, 2, 7),
    pair: 1,
    entryDirection: 1,
    entry: undefined,
    exit: 1.42,
    tp: undefined,
    sl: undefined,
    entryDatetime: '2021-2-6 9:29:39',
    comment: `まだGoTo何ちゃらとか言ってはるわ。
岸田総理で多少若返ったと期待していたが、どうしても観光業界と切れないバックが自民党にはおんぶしているようだ。
旅行に行く金を持っていて生活費に困っておらず、働かなくても良くって時間が余っている人を支援する必要がどこにある？
観光地の方は助かるかもしれないが、第5波が落ち着いている今はもっと生活に困っている人を支援すべきだと思う。
と言うと今度は子育て世帯とか非正規雇用者とかに限定したがるし。
何の支援もされず真面目に働いてる層が一番人数が多く、一番沢山税金を上乗せされると思うとやり切れない。`,
    files: {
      1: '',
      2: '',
      3: '',
    },
    reply: [],
    like: 2,
  },
]
