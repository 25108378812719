





















































import { computed, defineComponent, onMounted, reactive } from '@vue/composition-api'
import CompleteComponent from '@/components/Complete.vue'
import { datatableHeaders, getTypes, listNames } from '@/const/dashboard'
import AppLink from '@/components/Parts/Link.vue'
import { DashboardProps, DashboardInterface } from '@/types'
import { useRequest } from '@/hooks/useRequest'
import { formatDate } from '@/libs'

export default defineComponent({
  components: {
    CompleteComponent,
    AppLink,
  },
  setup(_, context) {
    const { request } = useRequest()
    const compFlg = computed(() => typeof context.root.$route.query?.regist_complet !== 'undefined')
    const dashboardData = reactive<DashboardProps>({
      information: [],
      workshop: [],
      events: [],
    }) as DashboardInterface

    onMounted(async () => {
      const { method, uri } = context.root.$appConfig.endpoints.getPosts
      for (const [type, id] of Object.entries(getTypes)) {
        const page = 5
        const customeUri = `${uri}?type=${id}&per_page=${page}`
        const result = await request(customeUri, method, {})
        if (result.data.length > 0) {
          for (const row of result.data) {
            dashboardData[type].push({
              ...row,
              id: row.id,
              title: row.title,
              detail: row.detail,
              status: row.status,
              start_date: formatDate(row.start_date, 'YYYY/MM/DD (ddd)'),
              start_time: formatDate(row.start_date, 'HH:mm'),
              created_at: formatDate(row.created_at, 'YYYY/MM/DD (ddd)'),
            })
          }
        }
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClickRow = (item: any) => {
      const { id, post_type_id } = item
      switch (post_type_id) {
        case 1:
          context.root.$router.push(`/information/${id}`)
          break
        case 2:
          context.root.$router.push(`/workshop/${id}`)
          break
        case 3:
          context.root.$router.push(`/events/${id}`)
          break
      }
    }
    const rowClass = () => {
      return 'hover-pointer'
    }
    const handleToList = (type: string) => {
      context.root.$router.push(`/${type}`)
    }

    const createUrl = (type: string, id: number) => {
      if (type === 'information') {
        return `/information/${id}`
      } else if (type === 'workshop') {
        return `/workshop/${id}`
      } else if (type === 'events') {
        return `/events/${id}`
      }
    }
    return { dashboardData, datatableHeaders, listNames, compFlg, createUrl, handleToList, onClickRow, rowClass }
  },
})
