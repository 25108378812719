































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import LinkComponent from '@/components/Parts/Link.vue'
import { validate } from 'vee-validate'

export default defineComponent({
  props: {
    input: {
      type: String,
      required: true,
      default: '',
    },
  },
  components: {
    LinkComponent,
  },
  setup(props, context) {
    const proxyInput = computed<string>({
      get: () => props.input,
      set: (val) => context.emit('update:input', val),
    })
    watch(
      () => proxyInput.value,
      (val) => (val !== '' ? (error.value = '') : '')
    )

    const error = ref<string>('')

    const submit = async () => {
      const res = await validate(proxyInput.value, 'required', { name: '審査論文' })
      if (res.errors.length > 0) {
        error.value = res.errors[0]
      } else {
        context.emit('confirm')
      }
    }
    return { proxyInput, submit, error }
  },
})
