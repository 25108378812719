










import { defineComponent, provide } from '@vue/composition-api'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import Notification from '@/components/Notification.vue'
import { useNotification, NotificationInjectKey } from '@/hooks/useNotification'
import { useNavigation, NavigationInjectKey } from '@/hooks/useNavigation'

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
    Notification,
  },
  setup() {
    const NotificationService = useNotification()
    const NavigationService = useNavigation()
    provide(NotificationInjectKey, NotificationService)
    provide(NavigationInjectKey, NavigationService)
    return {}
  },
})
