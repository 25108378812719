
























































import { computed, defineComponent, reactive } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const proxyOpen = computed({
      get: () => props.open,
      set: (val) => context.emit('update:open', val),
    })

    const inputs = reactive({
      newEmail: '',
      newEmailConfirm: '',
    })

    const resetEmailState = () => {
      const emailobserver = context.refs.emailobserver as InstanceType<typeof ValidationObserver>
      emailobserver.reset()
      inputs.newEmail = ''
      inputs.newEmailConfirm = ''
    }

    const handleChange = async () => {
      const emailobserver = context.refs.emailobserver as InstanceType<typeof ValidationObserver>
      if (await emailobserver.validate()) {
        proxyOpen.value = false
        context.emit('change-complete-email', inputs.newEmail, inputs.newEmailConfirm)
      }
    }
    return { proxyOpen, inputs, handleChange, resetEmailState }
  },
})
