















































































import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  components: {},
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const error = ref<boolean>(false)
    const errorMessage = ref<string>('')
    const proxyOpen = computed({
      get: () => props.open,
      set: (val) => context.emit('update:open', val),
    })

    const inputs = reactive({
      nowPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    })

    const handleChange = async () => {
      const passobserver = context.refs.passobserver as InstanceType<typeof ValidationObserver>
      if (await passobserver.validate()) {
        proxyOpen.value = false
        context.emit('change-complete-password', inputs.nowPassword, inputs.newPassword, inputs.newPasswordConfirm)
      }
    }

    const resetPasswordState = () => {
      const passobserver = context.refs.passobserver as InstanceType<typeof ValidationObserver>
      passobserver.reset()
      inputs.nowPassword = ''
      inputs.newPassword = ''
      inputs.newPasswordConfirm = ''
    }
    return { proxyOpen, inputs, error, errorMessage, handleChange, resetPasswordState }
  },
})
