


























import { defineComponent, computed, SetupContext, ref } from '@vue/composition-api'
import { PAYMENT_OR_CANCEL } from '@/const/paymentOrCancel'
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'payment',
    },
  },
  setup(props, ctx: SetupContext) {
    const panelState = ref([])
    const theTitle = computed(() => {
      return props.mode === 'payment' ? '参加費の入金手続きについて' : 'キャンセル手順について'
    })
    const dialog = computed({
      get: () => props.show,
      set: (val) => {
        panelState.value = []
        ctx.emit('update', val)
      },
    })
    const items = computed(() => {
      return PAYMENT_OR_CANCEL[props.mode]
    })
    return {
      dialog,
      theTitle,
      items,
      panelState,
    }
  },
})
