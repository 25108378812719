var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 my-2",attrs:{"items":_vm.breadItems}}),_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.listNames[_vm.type]))]),_c('v-data-table',{attrs:{"items":_vm.dataList,"headers":_vm.dataTableHeader,"item-class":_vm.row_classes,"hide-default-footer":"","disable-sort":""},on:{"click:row":_vm.onRowClickEvent},scopedSlots:_vm._u([(_vm.type === 'information')?{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at, 'YYYY/MM/DD (ddd) HH:mm'))+" ")]}}:null,(_vm.type === 'information')?{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('app-link',{on:{"click":function($event){return _vm.handleToEntry(item.id)}}},[_vm._v(_vm._s(item.title))])]}}:null,{key:"item.files",fn:function(ref){
var item = ref.item;
return [(_vm.checkFiles(item.files))?_c('div',{staticClass:"d-flex flex-wrap justify-space-around align-center"},_vm._l((item.files),function(file,key){return _c('img',{key:("file-" + key),staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.s3ReplaceUrl(file),"contain":"","width":"52"},on:{"click":function($event){_vm.handleOverlay(_vm.s3ReplaceUrl(file))}}})}),0):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.statusLabelClass(item.status_id)},[_vm._v(_vm._s(item.status))])]}}],null,true)})],1),_c('v-overlay',{attrs:{"value":_vm.overlayStates.show}},[_c('a',{on:{"click":function($event){_vm.overlayStates.show = !_vm.overlayStates.show}}},[_c('v-img',{attrs:{"src":_vm.overlayStates.src,"contain":"","max-width":"80vw"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }