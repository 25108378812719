






import { defineComponent, onMounted, PropType, reactive, ref, watch } from '@vue/composition-api'
import Chart from '@/components/Chart.vue'
import { ChartOptions } from 'chart.js'
import { DatasetType, LineRowInterface, LineResponseProps } from '@/types'
import { colors } from '@/const/colorPalette'

export default defineComponent({
  components: {
    Chart,
  },
  props: {
    results: {
      type: Object as PropType<LineResponseProps>,
      required: true,
    },
  },
  setup(props) {
    const graphType = 1
    const chartData = reactive<Partial<DatasetType>>({
      labels: undefined,
      datasets: [],
    })
    const options = reactive<Partial<ChartOptions>>({})

    const getLabels = (target: LineResponseProps) => {
      const labels: string[] = []
      Object.entries(target).map((v) => {
        if (v[0] === 'period' || v[0] === 'label') return
        const row = v[1] as LineRowInterface
        row.data.map((d) => {
          if (!labels.includes(d.x)) {
            labels.push(d.x)
          }
        })
      })
      return labels
    }

    const setData = (target: LineResponseProps) => {
      chartData.labels = getLabels(target)
      Object.entries(target).map((val) => {
        const keyname = val[0] as string
        if (keyname === 'period' || keyname === 'label') return
        const row = val[1] as LineRowInterface
        const color = colors[graphType][keyname as keyof typeof colors[typeof graphType]]
        chartData.datasets?.push({
          data: row.data.map((v) => v.y),
          label: row.label,
          backgroundColor: color,
          borderColor: color,
        })
      })
    }

    const setOptions = () => {
      options.responsive = true
      options.maintainAspectRatio = true
      options.scales = {
        ...options?.scales,
        y: {
          ...options.scales?.y,
        },
      }
      // options.scales?.y?.type = 'right' || undefined
    }

    const proxyResult = ref<LineResponseProps>(props.results)

    watch(
      () => props.results,
      (val) => {
        setTimeout(() => {
          chartData.datasets?.splice(-chartData.datasets.length)
          setData(val)
          setOptions()
        }, 300)
      }
    ),
      { deep: true }

    onMounted(() => {
      setTimeout(() => {
        setData(proxyResult.value)
        setOptions()
      }, 300)
    })

    return { chartData, options }
  },
})
