/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _Vue from 'vue'
import VueRouter from 'vue-router'
import { Store } from 'vuex'
import AuthVuexInstance, { State as AuthState } from '@/store/auth'
import Auth from '@/libs/Auth'
import { RootState } from '@/types'
type OptionsType = {
  router: VueRouter
  store: Store<RootState>
}

export default {
  install(Vue: typeof _Vue, { store, router }: OptionsType) {
    store.registerModule('credentials', AuthVuexInstance)
    const auth = new Auth(store)
    Vue.prototype.$auth = {
      user: () => {
        return store.getters['credentials/account']
      },
      logout: async () => {
        await auth.signOut()
      },
      /**
       * ログインを実施します
       * @param payload
       */
      login: async (payload: AuthState) => {
        store.dispatch('credentials/accepte', payload)
      },
      tokens: () => {
        return store.getters['credentials/tokens']
      },
    }
    router.beforeEach(async (to, from, next) => {
      //const user = (await auth.getUserCertificate()) || false
      if (!to.matched.some((record) => record.meta.requiresAuth)) {
        return next()
      }
      //ログインしていない場合はログイン画面へ遷移
      if (!(await auth.getUserCertificate())) {
        return next({
          path: '/login',
        })
      }
      return next()
    })
  },
}
