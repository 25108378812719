


















import { defineComponent, SetupContext, reactive, onMounted, inject, computed } from '@vue/composition-api'
import { apiClient, refreshAccessToken, headerWithAccessToken } from '@/libs'
import { NotificationInjectKey } from '@/hooks/useNotification'
export default defineComponent({
  setup(_, ctx: SetupContext) {
    const notity = inject(NotificationInjectKey)
    const state = reactive({
      isDiscordActivate: false,
      loading: false,
    })
    const discordAuthActive = computed(() => {
      const { refresh_token } = ctx.root.$auth.tokens()
      const url = '/api/oauth/discord/activate'
      return `${url}?refresh_token=${refresh_token}`
    })
    const onDisactive = async () => {
      state.loading = true
      const { uri } = ctx.root.$appConfig.endpoints.discordDisactivate
      const opitons = {
        headers: headerWithAccessToken(uri),
      }
      const res = await apiClient
        .post(uri, {}, opitons)
        .then((res) => {
          refreshAccessToken(res.headers)
          return true
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          return false
        })
        .finally(() => {
          state.loading = false
        })
      if (res) {
        notity?.show('Discordログインを無効にしました。', 'success')
      } else {
        notity?.show('サーバーの通信に失敗しました。時間をおいて再度お試しください', 'error')
      }
      state.isDiscordActivate = !res
    }

    onMounted(() => {
      const user = ctx.root.$auth.user()
      if (user?.discord_id) {
        state.isDiscordActivate = true
      }
    })
    return {
      state,
      onDisactive,
      discordAuthActive,
    }
  },
})
