


































































































































































import { defineComponent, onMounted, reactive, ref, inject } from '@vue/composition-api'
import FileUpload from '@/components/FileUpload.vue'
import { useInit } from '@/hooks/useInit'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { FileUploadResponseType, InvestmentItemType, AttachmentFileType } from '@/types'
import { headerWithAccessToken, refreshAccessToken } from '@/libs'
import axios from 'axios'
const client = axios.create({
  withCredentials: true,
})
export default defineComponent({
  components: { FileUpload },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const user = vm.$auth.user()
      const capabilitie_investment_id = user?.capabilitie_investment_id
      if (capabilitie_investment_id === 1) {
        vm.$router.push('/investment')
      }
    })
  },
  setup(_, context) {
    const notificationService = inject(NotificationInjectKey)
    const loading = ref(false)
    const modal = ref(false)
    const { data: initData, fetch } = useInit()
    const inputs = reactive<InvestmentItemType>({
      currency_pair_id: null,
      entry_direction_id: null,
      entry: null,
      exit: null,
      schedule_tp: null,
      schedule_sl: null,
      entry_date: null,
      body: null,
      files: [],
      file_ids: [],
    })

    const breadItems = [
      {
        text: '投資勉強会',
        disabled: false,
        href: '/investment',
      },
      {
        text: 'みんなのトレード報告',
        disabled: false,
        href: '/investment/threads',
      },
      {
        text: 'トレード結果報告',
        disabled: true,
        href: '/investment',
      },
    ]

    const overlayState = reactive({
      state: false,
      src: '',
    })

    const overlayPreview = (img: string) => {
      overlayState.src = img
      overlayState.state = true
    }

    const submit = async () => {
      loading.value = true
      const { uri } = context.root.$appConfig.endpoints.threadPost
      await client
        .post(uri, inputs, {
          headers: headerWithAccessToken(uri),
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          modal.value = true
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          notificationService?.show('サーバーとの通信に失敗しました', 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }
    const removeFile = (item: AttachmentFileType | FileUploadResponseType) => {
      inputs.files = inputs.files.filter((file) => file.id !== item.id)
      inputs.file_ids = inputs.file_ids.filter((id) => id !== item.id)
    }
    const replaceS3path = (url: string) => {
      const domain = context.root.$appConfig.s3
      return `${domain}${url}`
    }

    const uploadSuccess = (item: FileUploadResponseType) => {
      const domain = context.root.$appConfig.s3
      item.url = `${domain}${item.url}`
      const img = document.createElement('img')
      img.src = item.url
      img.onload = () => {
        inputs.files.push(item)
        inputs.file_ids.push(item.id)
      }
    }

    onMounted(async () => {
      await fetch()
    })

    return {
      replaceS3path,
      breadItems,
      inputs,
      submit,
      uploadSuccess,
      initData,
      removeFile,
      overlayState,
      overlayPreview,
      loading,
      modal,
    }
  },
})
