

















import { defineComponent, ref, computed, inject } from '@vue/composition-api'
import SubscriptionComponent from '@/components/Investment/Subscription/Index.vue'
import TrainingComponent from '@/components/Investment/Training/Index.vue'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { useRequest } from '@/hooks/useRequest'

export default defineComponent({
  components: { SubscriptionComponent, TrainingComponent },
  setup(_, context) {
    const { request } = useRequest()
    const notificationService = inject(NotificationInjectKey)
    const input = ref<string>('')
    const confirm = ref<boolean>(false)
    const complete = ref<boolean>(false)

    const user = computed(() => {
      return context.root.$auth.user()
    })

    const handleApply = async () => {
      const { method, uri } = context.root.$appConfig.endpoints.investmentRequests
      const result = await request(uri, method, { thesis: input.value })
      if (result === false) {
        notificationService?.show('データ通信に失敗しました', 'error')
      } else {
        confirm.value = false
        complete.value = true
      }
    }

    return { user, input, confirm, complete, handleApply }
  },
})
