













import { computed, defineComponent, ref } from '@vue/composition-api'
import MenuComponent from '@/components/Menu.vue'

export default defineComponent({
  name: 'AppHeader',
  components: {
    MenuComponent,
  },
  setup(_, context) {
    const breakpoint = computed(() => context.root.$vuetify.breakpoint.name)
    const fontSize = computed(() => (breakpoint.value === ('lg' || 'md') ? 'display-1' : 'headline'))
    const drawer = ref<boolean | null>(false)
    return { breakpoint, fontSize, drawer }
  },
})
