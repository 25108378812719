import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { AuthState, RootState } from '@/types'

import dayjs from 'dayjs'

export type State = {
  account: AuthState | undefined
  tokens: {
    access_token: string
    expires_in: number
    refresh_token: string
    token_type: string
    expires_timestamp: number
  }
  user?: AuthState | undefined
}
const state: State = {
  account: undefined,
  tokens: {
    access_token: '',
    expires_in: 0,
    refresh_token: '',
    token_type: 'bearer',
    expires_timestamp: 0,
  },
}
const getters: GetterTree<State, RootState> = {
  account: (state: State) => state.account,
  tokens: (state: State) => state.tokens,
}
const actions: ActionTree<State, RootState> = {
  accepte({ commit }, payload: AuthState) {
    commit('accepte', payload)
  },
  signout({ commit }) {
    commit('signout')
  },
  updateAccessToken({ commit }, payload) {
    commit('updateToken', payload)
  },
}

const mutations: MutationTree<State> = {
  updateToken(state: State, payload) {
    state.tokens.access_token = payload.access_token
    state.tokens.expires_timestamp = payload.timestamp
  },
  accepte(state: State, payload: AuthState) {
    state.account = payload.user
    state.tokens = payload.token
    state.tokens.expires_timestamp = dayjs().add(payload.token.expires_in, 'second').unix()
  },
  signout(state: State) {
    state.account = undefined
    state.tokens = {
      access_token: '',
      expires_in: 0,
      refresh_token: '',
      token_type: 'bearer',
      expires_timestamp: 0,
    }
    state.user = undefined
  },
}

const auth: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

export default auth
