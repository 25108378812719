














import { defineComponent, PropType } from '@vue/composition-api'
import { ProfileProps } from '@/const/userData'
import LinkComponent from '@/components/Parts/Link.vue'

export default defineComponent({
  components: {
    LinkComponent,
  },
  props: {
    email: {
      type: String as PropType<ProfileProps['email']>,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
