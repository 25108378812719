






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    message: {
      type: String,
      required: false,
      default: `入力内容に謝りがあります。<br />
      入力項目の下に赤文字でエラー内容が記載されている項目を、再度選択または入力してください。`,
    },
  },
  // setup() {},
})
