import { GetTypesInterface } from '@/types'
export const datatableHeaders = {
  invest: [
    {
      text: '投稿日',
      value: 'created_at',
    },
    {
      text: 'タイトル',
      value: 'title',
    },
  ],
  information: [
    {
      text: '投稿日',
      value: 'created_at',
      width: '15%',
    },
    {
      text: 'タイトル',
      value: 'title',
    },
  ],
  workshop: [
    {
      text: '開催日',
      value: 'start_date',
      width: '15%',
    },
    {
      text: '開催時刻',
      value: 'start_time',
      width: '10%',
    },
    {
      text: '勉強会名',
      value: 'title',
      width: '35%',
    },
    {
      text: '募集ステータス',
      value: 'status',
    },
    {
      text: '場所',
      value: 'location',
    },
    {
      text: '',
      value: 'actions',
    },
  ],
  events: [
    {
      text: '開催日',
      value: 'start_date',
      width: '15%',
    },
    {
      text: '開催時刻',
      value: 'start_time',
      width: '10%',
    },
    {
      text: 'イベント名',
      value: 'title',
      width: '35%',
    },
    {
      text: '募集ステータス',
      value: 'status',
    },
    {
      text: '場所',
      value: 'location',
    },
    {
      text: '',
      value: 'actions',
    },
  ],
}

export const getTypes = {
  information: 1,
  workshop: 2,
  events: 3,
} as GetTypesInterface

export const listNames = {
  information: 'お知らせ',
  workshop: '勉強会',
  events: 'イベント',
  'investment-information': '投資勉強会',
} as GetTypesInterface

export const informationListTableHeader = [
  {
    text: '投稿日',
    value: 'created_at',
    width: '20%',
  },
  {
    text: 'タイトル',
    value: 'title',
  },
  {
    text: '', // files
    value: 'files',
  },
]
export const workshopListTableHeader = [
  {
    text: '開催日',
    value: 'start_date',
    width: '12%',
  },
  {
    text: '開催時刻',
    value: 'start_time',
    width: '8%',
  },
  {
    text: '募集ステータス',
    value: 'status',
    width: '10%',
  },
  {
    text: '勉強会名',
    value: 'title',
  },
  {
    text: '場所',
    value: 'location',
  },
  {
    text: '参加条件',
    value: 'join_condition',
  },
  {
    text: '', // files
    value: 'files',
  },
]

export const eventListTableHeader = [
  {
    text: '開催日',
    value: 'start_date',
    width: '12%',
  },
  {
    text: '開催時刻',
    value: 'start_time',
    width: '8%',
  },
  {
    text: '募集ステータス',
    value: 'status',
    width: '10%',
  },
  {
    text: 'イベント名',
    value: 'title',
  },
  {
    text: '場所',
    value: 'location',
  },
  {
    text: '参加条件',
    value: 'join_condition',
  },
  {
    text: '', // files
    value: 'files',
  },
]
