









































































































































import { defineComponent, inject, onMounted, reactive, ref, computed } from '@vue/composition-api'
import LinkComponent from '@/components/Parts/Link.vue'
import { FormOptionsType } from '../types'
import { useRequest } from '@/hooks/useRequest'
import { ValidationObserver } from 'vee-validate'
import { NotificationInjectKey } from '@/hooks/useNotification'
import PaymentCancelPolicy from '@/components/PaymentCancelPolicy.vue'

export default defineComponent({
  components: {
    LinkComponent,
    PaymentCancelPolicy,
  },
  setup(_, context) {
    const { request } = useRequest()
    const stepStatus = ref(1)
    const notificationService = inject(NotificationInjectKey)
    const loading = ref(false)
    const paymentCacnelPolicyState = reactive<{
      show: boolean
      mode: 'cancel' | 'payment'
    }>({
      show: false,
      mode: 'payment',
    })
    const computedGroup = {
      kind: computed(() => {
        return inits.consultation.consult.find((item) => item.id === inputs.consult_id)?.name
      }),
      location: computed(() => {
        return inits.consultation.location.find((item) => item.id === inputs.location_id)?.name
      }),
    }

    const pageState = ref<'idle' | 'confirm' | 'complete'>('idle')

    const user = context.root.$auth.user()

    const inputs = reactive({
      consult_id: undefined,
      location_id: undefined,
      detail: undefined,
    })

    const inits = reactive<{
      consultation: {
        consult: FormOptionsType[]
        location: FormOptionsType[]
      }
    }>({
      consultation: {
        consult: [],
        location: [],
      },
    })

    /**
     * 確認処理
     */
    const confirmHandler = async (mode: 'confirm' | 'idle') => {
      if (mode === 'confirm') {
        const observer = context.refs.observer as InstanceType<typeof ValidationObserver>
        if (!(await observer.validate())) {
          return false
        }
        stepStatus.value = 2
      } else {
        stepStatus.value = 1
      }

      pageState.value = mode
    }

    /**
     * 送信処理
     **/
    const handleApply = async () => {
      stepStatus.value = 3
      loading.value = true
      const { method, uri } = context.root.$appConfig.endpoints.consultations
      const result = await request(uri, method, inputs)
      if (result === false) {
        notificationService?.show('データ通信に失敗しました', 'error')
        return
      }
      setTimeout(() => {
        loading.value = false
      }, 2000)
      pageState.value = 'complete'
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const onPaymentCancelPolicyDialogOpen = (mode: 'payment' | 'cancel') => {
      paymentCacnelPolicyState.show = true
      paymentCacnelPolicyState.mode = mode
    }
    const onPaymentCancelPolicyDialogClose = (value: boolean) => {
      paymentCacnelPolicyState.show = value
    }
    onMounted(async () => {
      const { method, uri } = context.root.$appConfig.endpoints.init
      const init = await request(uri, method, {})
      const { consultation } = init
      inits.consultation = { ...consultation }
    })

    return {
      user,
      inputs,
      inits,
      handleApply,
      paymentCacnelPolicyState,
      onPaymentCancelPolicyDialogOpen,
      onPaymentCancelPolicyDialogClose,
      pageState,
      confirmHandler,
      computedGroup,
      loading,
      stepStatus,
    }
  },
})
