/**
 * 仮データとして実装
 */

export const PAYMENT_OR_CANCEL: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
} = {
  payment: [
    {
      title: '投資勉強会',
      body: 'AAAAAAAAAAAAAAAA',
    },
    {
      title: '各種イベント',
      body: 'AAAAAAAAAAAAAAAA',
    },
  ],
  cancel: [
    {
      title: '投資勉強会のキャンセル手順',
      body: 'AAAAAAAAAAAAAAAA',
    },
    {
      title: '各種イベントのキャンセル手順',
      body: 'AAAAAAAAAAAAAAAA',
    },
  ],
}
