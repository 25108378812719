






















import { defineComponent, PropType } from '@vue/composition-api'

export type Props = {
  topMessage: string
  email: string
}

export default defineComponent({
  name: 'Complete',
  props: {
    topMessage: {
      type: String as PropType<Props['topMessage']>,
      required: true,
    },
    email: {
      type: String as PropType<Props['email']>,
      required: false,
      default: undefined,
    },
  },
})
