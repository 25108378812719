














import { defineComponent, onMounted, SetupContext, inject } from '@vue/composition-api'
import CompleteComponent from '@/components/Complete.vue'
import { NavigationInjectKey } from '@/hooks/useNavigation'
export default defineComponent({
  components: {
    CompleteComponent,
  },
  setup(_, ctx: SetupContext) {
    const navigation = inject(NavigationInjectKey)
    onMounted(async () => {
      navigation?.reject()
      await ctx.root.$auth.logout()
    })
    return {}
  },
})
