




























































import { defineComponent, reactive, ref, watch, onMounted } from '@vue/composition-api'
import { useRequest } from '@/hooks/useRequest'
import ReportList from '@/components/ReportList.vue'
import LineChart from '@/components/Parts/Chart/Line.vue'
import BarChart from '@/components/Parts/Chart/Bar.vue'
import HorizonBarCart from '@/components/Parts/Chart/HorizonBar.vue'
import StatisticsList from '@/components/Parts/StatisticsList.vue'
import FileUpload from '@/components/Investment/FileUpload.vue'
import {
  HorizonBarResponseProps,
  LineResponseProps,
  BarResponseProps,
  KeyObject,
  ReportListProps,
  ReportListPaginateProps,
  StatisticsListProps,
} from '@/types'

export default defineComponent({
  components: {
    ReportList,
    LineChart,
    BarChart,
    HorizonBarCart,
    StatisticsList,
    FileUpload,
  },
  setup(_, context) {
    const { request } = useRequest()

    const breadItems = [
      {
        text: '投資勉強会',
        disabled: false,
        href: '/investment',
      },
      {
        text: 'あなたのトレード成績表・自己トレード分析',
        disabled: true,
      },
    ]

    const graphType = [
      { key: 1, title: 'トレード結果の推移' },
      { key: 2, title: '時間帯別損益合算' },
      { key: 3, title: '曜日別損益合算' },
      { key: 4, title: '通貨ペア別損益比率' },
      { key: 5, title: '通貨ペア別トレード統計' },
    ]

    const reportList = ref<ReportListProps[]>([])
    const reportPaginate = reactive<ReportListPaginateProps>({
      current_page: 0,
      from: 0,
      last_page: 0,
      last_page_url: undefined,
      per_page: 2,
      total: 0,
      page: 0,
      max_page: 0,
    })

    const periodOptions = [
      { id: 0, value: '全期間' },
      { id: 1, value: '1ヶ月' },
      { id: 3, value: '3ヶ月' },
      { id: 6, value: '6ヶ月' },
      { id: 12, value: '1年' },
    ]

    const results = reactive<{
      1?: LineResponseProps
      2?: BarResponseProps
      3?: BarResponseProps
      4?: HorizonBarResponseProps
      5?: StatisticsListProps
    }>({
      1: {} as LineResponseProps,
      2: {} as BarResponseProps,
      3: {} as BarResponseProps,
      4: {} as HorizonBarResponseProps,
      5: {} as StatisticsListProps,
    }) as KeyObject

    const selectPeriod = ref(1)

    const getTrades = async (page = 1) => {
      const { method, uri } = context.root.$appConfig.endpoints.getTrades
      const result = await request(`${uri}?per_page=${reportPaginate.per_page}&page=${page}`, method, {})
      reportList.value = result.data
      reportPaginate.total = result.total
      reportPaginate.current_page = result.current_page
      reportPaginate.page = page
      reportPaginate.max_page = Math.ceil(result.total - reportPaginate.per_page)
    }

    const getGraph = async () => {
      const { method, uri } = context.root.$appConfig.endpoints.getGraph
      graphType.map(async (g) => {
        const graphtype = g.key
        const requestUri = `${uri}?graph_type=${graphtype}&period_months=${selectPeriod.value}`
        const result = await request(requestUri, method, {})
        results[graphtype] = result
      })
    }

    const handleChangeGraph = async () => {
      await getGraph()
    }

    watch(
      () => reportPaginate.page,
      (val) => {
        getTrades(val)
      }
    ),
      { deep: true }

    onMounted(async () => {
      await getTrades()
      await getGraph()
    })
    return { breadItems, periodOptions, selectPeriod, reportList, reportPaginate, results, handleChangeGraph }
  },
})
