















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    body: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(_, context) {
    const handleOk = () => {
      context.emit('handle-ok')
    }
    const handleCancel = () => {
      context.emit('handle-cancel')
    }
    return { handleOk, handleCancel }
  },
})
