import { Store } from 'vuex'
import axios from 'axios'
import { RootState } from '@/types'
import AppConfig from '@/config'
import { headerWithAccessToken } from '@/libs'

export default class Auth {
  private store: Store<RootState>
  constructor(store: Store<RootState>) {
    this.store = store
    axios.defaults.withCredentials = true
  }
  async getUserCertificate(): Promise<boolean> {
    const { uri } = AppConfig.endpoints.login
    const user = this.store.getters['credentials/account']
    const tokens = this.store.getters['credentials/tokens']
    const storage = localStorage.getItem('vuex') || null
    if (!user || tokens.refresh_token === '') {
      if (!storage) {
        return false
      }
      const credentials = JSON.parse(storage).credentials
      const res = await axios
        .post(uri, {
          type: 'refresh',
          token: credentials.tokens.refresh_token,
        })
        .then((res) => {
          const data = res.data
          this.store.dispatch('credentials/accepte', data)
          return true
        })
        .catch(() => {
          return false
        })
      return res
    }
    return true
  }
  async signOut(): Promise<void> {
    const headers = headerWithAccessToken('/api/logout')
    const { uri } = AppConfig.endpoints.logout
    await axios
      .post(uri, {}, { headers })
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
    this.store.dispatch('credentials/signout')
  }
}
