











































































import { defineComponent, computed, PropType, SetupContext } from '@vue/composition-api'
import { ThreadItemType } from '@/types'
import ThreadComment from '@/components/Investment/Threads/ThreadComment.vue'
import { formatDate, isElapsedTime, round, imageUrlReplace } from '@/libs'
export default defineComponent({
  components: {
    ThreadComment,
  },
  props: {
    thread: {
      type: Object as PropType<ThreadItemType>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const config = context.root.$appConfig

    /**
     * pipsを表示する
     */
    const displayPips = computed(() => {
      let sign = '+'
      const { entry, exit } = props.thread
      const rest = Number(entry) - Number(exit)
      if (Math.sign(rest) === -1) {
        sign = '-'
      }
      return `${sign} ${Math.abs(round(rest, 1))} pips`
    })

    const displayReckoning = computed(() => {
      const { schedule_tp, schedule_sl, entry, entry_direction } = props.thread
      const tp = Math.abs(Number(schedule_tp) - Number(entry))
      const sl = Math.abs(Number(schedule_sl) - Number(entry))
      if (entry_direction.toLowerCase() === 'buy') {
        return `${round(tp / sl, 2)}`
      } else {
        return `${round(sl / tp, 2)}`
      }
    })
    /**
     * ユーザーのアバター画像のパスを変更する
     */
    const avatarImagePath = computed(() => {
      const domain = config.s3
      return `${domain}${props.thread.created_user.image_url}`
    })

    /**
     * 最新の返信が期限切れかどうか確認する
     */
    const islatestReplyExpiration = computed(() => {
      const latest = props.thread.comments.slice(-1)[0] || null
      if (!latest) {
        return false
      }
      return isElapsedTime(latest.created_at, 73)
    })

    const isEditable = computed(() => {
      const userId = props.thread.created_user.id
      const currentUser = context.root.$auth.user()
      if (currentUser?.id === userId || currentUser?.role !== '一般ユーザー') {
        return true
      }
      return false
    })

    const onPreview = (image: string) => {
      context.emit('preview', image)
    }

    const addComment = (thread: ThreadItemType) => {
      context.emit('addComment', thread)
    }

    const removeThread = (thread: ThreadItemType) => {
      context.emit('removeThread', thread)
    }

    const toTransition = (id: number) => {
      context.root.$router
        .push({ name: 'InvestmentThreadUser', params: { id: id.toString() } })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          return
        })
    }

    return {
      avatarImagePath,
      formatDate,
      imageUrlReplace,
      islatestReplyExpiration,
      displayPips,
      displayReckoning,
      onPreview,
      isEditable,
      addComment,
      removeThread,
      toTransition,
    }
  },
})
