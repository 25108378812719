export const unauthorizedNavi = [
  {
    label: '会員専用ページ利用方法',
    link: '/faqs',
  },
  {
    label: 'ログイン',
    link: '/login',
  },
]

export const normalNavi = [
  {
    label: '勉強会・イベント申し込み',
    link: '/',
  },
  {
    label: '個別相談申し込み',
    link: '/consultation',
  },
  {
    label: '投資勉強会',
    link: '/investment',
  },
  {
    label: '会員専用ページ利用方法',
    link: '/procedure',
  },
  {
    label: 'ログアウト',
    link: '/logout',
  },
]

export const AdminNavi = [
  {
    label: '勉強会・イベント申し込み',
    link: '/',
  },
  {
    label: '個別相談申し込み',
    link: '/consultation',
  },
  {
    label: '投資勉強会',
    link: '/investment',
  },
  {
    label: '会員専用ページ利用方法',
    link: '/procedure',
  },
  {
    label: '管理画面',
    link: '/admin',
  },
  {
    label: 'ログアウト',
    link: '/logout',
  },
]
