





















import { defineComponent, reactive, SetupContext, inject } from '@vue/composition-api'
import { apiClient, headerWithAccessToken, mergeObject } from '@/libs'
import { NotificationInjectKey } from '@/hooks/useNotification'
export default defineComponent({
  setup(_, ctx: SetupContext) {
    const Notification = inject(NotificationInjectKey)
    const config = ctx.root.$appConfig.endpoints
    const state = reactive({
      file: null,
      loading: false,
      rules: [(value: File) => !value || value.size < 1024 * 1024 * 5 || '5MB以下のファイルを選択してください'],
    })
    // 1024 * 1024 * 5
    const onUploadEventHandler = async () => {
      state.loading = true
      const file = state.file
      const { uri } = config.csvUpload
      if (!file) {
        return
      }
      const params = new FormData()
      const options = mergeObject(
        {
          'Content-Type': 'multipart/form-data',
        },
        headerWithAccessToken(uri)
      )
      params.append('file', file)
      await apiClient
        .post(uri, params, { headers: options })
        .then(() => {
          Notification?.show('アップロードが完了しました', 'success')
        })
        .catch(() => {
          Notification?.show('ファイルの送信に失敗しました', 'error')
        })
        .finally(() => {
          state.loading = false
        })
      state.file = null
    }
    return {
      state,
      onUploadEventHandler,
    }
  },
})
