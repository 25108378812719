export default {
  host: 'http://ec2-18-178-195-185.ap-northeast-1.compute.amazonaws.com' || '',
  s3: 'https://d3kosm5x5q0u2w.cloudfront.net/',
  endpoints: {
    ping: {
      method: 'get',
      uri: '/api/ping',
    },
    authentice: {
      method: 'get',
      uri: '/api/auth',
    },
    login: {
      method: 'post',
      uri: '/api/login',
    },
    register: {
      method: 'post',
      uri: '/api/register',
    },
    init: {
      method: 'get',
      uri: '/api/init',
    },
    postsAdd: {
      method: 'post',
      uri: '/api/posts',
    },
    postsPut: {
      method: 'put',
      uri: '/api/posts',
    },
    postsJoin: {
      method: 'post',
      uri: '/api/posts/:id/join',
    },
    postsGet: {
      method: 'get',
      uri: '/api/posts',
    },
    consultations: {
      method: 'post',
      uri: '/api/consultations',
    },
    investmentRequests: {
      method: 'post',
      uri: '/api/investment-requests',
    },
    fileUpload: {
      uri: '/api/file/upload',
    },
    fileAvatarUpload: {
      uri: '/api/file/avatar',
    },
    getGraph: {
      uri: '/api/graph',
      method: 'get',
    },
    getTrades: {
      uri: '/api/trades',
      method: 'get',
    },
    changeEmailRequest: {
      uri: '/api/users/:id/confrim-email',
      method: 'post',
    },
    patchEmail: {
      uri: '/api/users/:id/email',
      method: 'patch',
    },
    patchPassword: {
      uri: '/api/users/:id/password',
      method: 'patch',
    },
    putUser: {
      uri: '/api/users/:id',
      method: 'put',
    },
    registerValidate: {
      method: 'get',
      uri: '/api/register-validate/:uuid',
    },
    createUser: {
      method: 'post',
      uri: '/api/users',
    },
    admin: {
      users: {
        method: 'get',
        uri: '/api/users',
      },
    },
    logout: {
      method: 'post',
      uri: '/api/logout',
    },
    getPosts: {
      method: 'get',
      uri: '/api/posts',
    },
    threads: {
      method: 'get',
      uri: '/api/threads',
    },
    threadsUser: {
      method: 'get',
      uri: '/api/threads/user/:userId',
    },
    threadPost: {
      method: 'post',
      uri: '/api/threads',
    },
    threadGet: {
      method: 'get',
      uri: '/api/threads/:id',
    },
    threadUpdate: {
      method: 'put',
      uri: '/api/threads/:id',
    },
    threadDelete: {
      method: 'delete',
      uri: '/api/threads/:id',
    },
    like: {
      method: 'post',
      uri: '/api/like/:comment_id',
    },
    unLike: {
      method: 'delete',
      uri: '/api/like/:like_id',
    },
    comment: {
      method: 'post',
      uri: '/api/threads/:id/comment',
    },
    csvExport: {
      method: 'get',
      uri: '/api/users/csv/export',
    },
    userUpdate: {
      method: 'put',
      uri: '/api/users/:id',
    },
    csvUpload: {
      method: 'put',
      uri: '/api/file/trades',
    },
    discordDisactivate: {
      method: 'post',
      uri: '/api/oauth/discord/desactivate',
    },
    getInviteCode: {
      method: 'get',
      uri: '/api/invitation-code',
    },
    postInviteCode: {
      method: 'post',
      uri: '/api/invitation-code',
    },
    usernameLogin: {
      method: 'post',
      uri: '/api/username/login',
    },
    passwordForgot: {
      method: 'post',
      uri: '/api/password/forgot',
    },
    passwordResetValidate: {
      method: 'get',
      uri: '/api/password/reset-validate/:uuid',
    },
    passwordReset: {
      method: 'post',
      uri: '/api/password/reset',
    },
  },
}
